import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ICCDetailModel, IPolicyDetails } from 'src/app/core/interfaces/app.interface';
import { AlertService } from 'src/app/core/services/alert.service';
import { PaymentService } from 'src/app/core/services/payment.service';
import { PolicyService } from 'src/app/core/services/policy.service';
import { isNullOrUndefined } from 'util';
import { PartialService } from '../../partials/partial.service';
import Swal from 'sweetalert2';

// Declare loader function
declare function loadingServiceShow(zindex, id, flag);
declare function loadingServiceHide(id);

@Component({
  selector: 'app-payment-installment',
  templateUrl: './payment-installment.component.html',
  styleUrls: ['./payment-installment.component.css']
})
export class PaymentInstallmentComponent implements OnInit {

  policyDetailsModel: IPolicyDetails = <IPolicyDetails>{};
  policyNumber: string;
  effectiveDate: string;
  expirationDate: string;
  installmentAmount: string;
  IsDefaultCard: boolean = false;
  IsCardExist: boolean;
  IsValidAmount: boolean = true;
  IsCancelPolicy: boolean = false;
  cardList: ICCDetailModel[] = [];
  selectedCcId:number;

  constructor( private policyService: PolicyService,
    private paymentService:PaymentService,
    private partialService: PartialService,
    private alertservice: AlertService,
    public datepipe: DatePipe,
    private router: Router,
    private modalService: BsModalService,
    private bsModalRef: BsModalRef
    ) { }

  ngOnInit(): void {
    //this.policyNumber = localStorage.getItem('policynumber');
    this.effectiveDate = localStorage.getItem('effectiveDate');
    this.expirationDate = localStorage.getItem('expirationDate');
    this.installmentAmount = localStorage.getItem('installmentAmount');
    this.installmentAmount = <any>this.modalService.config.initialState["amount"];
    this.policyNumber = <any>this.modalService.config.initialState["policyNumber"];
    this.getPolicyNumberByDetails(this.policyNumber);
    this.getAllCards();
  }

  getPolicyNumberByDetails(policyNumber: string) 
  {
    loadingServiceShow(1, 'dvPaymentDetail', true);
    this.policyService.PolicyDetailsByPolicyNumber(policyNumber).subscribe(data => {
      if (data.data != null) {
        if (data.status = 200) {
          this.policyDetailsModel = data.data;
          if (data != null && data.data != null && !isNullOrUndefined(data.data.status) && data.data.status != '') {
            localStorage.setItem("policyStatus", data.data.status);
            if (data.data.status.toLowerCase().includes("cancelled")) {
              this.partialService.isCancel = true;
              let cancelDate = this.datepipe.transform(this.policyDetailsModel.cancelDate, 'MM/dd/yyyy');
              localStorage.setItem("policyCancelDate", cancelDate);
              this.partialService.cancelDate = cancelDate;
            }
            else if (data.data.status.toLowerCase().includes("active")) {
              this.partialService.isActive = true;
            }
            else if (data.data.status.toLowerCase().includes("notice")) {
              let cancelDate = this.datepipe.transform(this.policyDetailsModel.cancelDate, 'MM/dd/yyyy');
              localStorage.setItem("policyCancelDate", cancelDate);
              this.partialService.cancelDate = cancelDate;
              this.partialService.isNotice = true;
            }
          }
        }
      }
      else{
        localStorage.clear();
        sessionStorage.clear();
      this.router.navigate(["/login"]);
      }
    }, error => {
      console.log("Error while policy details fetching!");
    })
  }

  getAllCards() {
    loadingServiceShow(1, 'dvPaymentDetail', true);
    this.paymentService.GetAllCards(this.policyNumber).subscribe(data => {
      if (data.data != null) {
        
        if (data.status = 200) {
          if (!isNullOrUndefined(data.data.ccList) && data.data.ccList.length > 0) {
            this.cardList = data.data.ccList;
            if(this.cardList!=null && this.cardList.length  > 0)
            {
              this.selectedCcId =  this.cardList.find(x=>x.isDefault == true).id;
            }
            for (var val of data.data.ccList) {
              this.IsCardExist = true;
            }
          }
          else 
          {
            this.addNewCard(); // redirect to new card screen
          }
        }
        else if (data.data.Message.includes("No Card")) 
        {
          this.addNewCard(); // redirect to new card screen
        }
        else {
          this.IsCardExist = false;
        }
      }
    }, error => {
      this.alertservice.warning("Invalid user details !");
    });
    loadingServiceHide('dvPaymentDetail');
  }

  addNewCard() 
  {
    this.router.navigate(['/payment-add-card'])
    return false;
  }

  close()
  {
    this.bsModalRef.hide();
  }

  payNow()
  {
    this.policyDetailsModel.PolicyNumber = isNullOrUndefined(this.policyNumber) ? localStorage.getItem('policynumber') : this.policyNumber;
      this.policyDetailsModel.amount = parseFloat(this.installmentAmount); // no need to set in policyDetailsModel
      this.policyDetailsModel.ccfees = parseFloat("6"); // static CC Fees 
      this.policyDetailsModel.CCId = this.selectedCcId;
      localStorage.setItem("paymentAmount", this.installmentAmount.toString()); // set payment amount in local Storage
      loadingServiceShow(1, 'dvPaymentDetail', true);
      this.paymentService.PayPolicyInstallment(this.policyDetailsModel).subscribe(data => {
        debugger;
        if(data.status==401)
        {
          Swal.fire('', 'Authentication Failed', 'warning')
          return false
        }
        if (data.data != null) 
        {
          if (data.status == 200) 
          {
            this.bsModalRef.hide();
            localStorage.setItem("transactionId", data.data.pgTransactionId);
            this.router.navigate(["/payment-success"]);
          }
          else if (data.status == 401 || data.status == 402) {
            loadingServiceHide('dvPaymentDetail');
            this.alertservice.warning(data.message);
            return false;
          }
        }
        loadingServiceHide('dvPaymentDetail');
      }, error => {
        loadingServiceHide('dvPaymentDetail');
        this.alertservice.warning("Payment Failed!");
      })
  }
}
