import { Component, OnInit } from '@angular/core';
import {LoginService} from '../../../core/services/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import {ICCDetailModel} from 'src/app/core/interfaces/app.interface';
import {PaymentService} from '../../../core/services/payment.service';
import {AlertService} from '../../../core/services/alert.service';
import { isNull, isNullOrUndefined } from 'util';
import { EPage } from 'src/app/core/enums/app.enums';
import { PartialService } from '../../partials/partial.service';
import Swal from 'sweetalert2';
// Declare loader function
declare function loadingServiceShow(zindex, id, flag);
declare function loadingServiceHide(id);

@Component({
  selector: 'app-payment-add-card',
  templateUrl: './payment-add-card.component.html',
  styleUrls: ['./payment-add-card.component.css']
})
export class PaymentAddCardComponent implements OnInit {
  firstName:string;
  lastName:string;
  policyNumber:string;
  effectiveDate:string;
  expirationDate:String;
  message:string;
  installmentAmount:string;
  ccDetailForm: ICCDetailModel = <ICCDetailModel>{};
  isAdded :boolean = true;
  ccCount : boolean = false;
  strPayment:string = EPage[EPage.PaymentCard].toString();
  constructor(
    private loginService:LoginService,
    private partialService:PartialService,
    private router: Router,
    private route: ActivatedRoute,
    private paymentService:PaymentService,
    private alertservice:AlertService
  ) { }

  ngOnInit(){
    debugger;
    loadingServiceShow(1, 'dvPaymentAddCard', true);
    this.firstName=localStorage.getItem('firstname');
    this.lastName=localStorage.getItem('lastname');
    this.policyNumber=localStorage.getItem('policynumber');
    this.effectiveDate=localStorage.getItem('effectiveDate');
    this.expirationDate=localStorage.getItem('expirationDate');
    this.installmentAmount = localStorage.getItem('installmentAmount');
    
    if(!isNullOrUndefined(this.installmentAmount))
    {
      this.installmentAmount = parseFloat(this.installmentAmount).toFixed(2).toString();
    }
    loadingServiceHide('dvPaymentAddCard');
  }

  doLogout(){
    this.loginService.logOut().subscribe(data=>
    {
      this.partialService.ClearPartialValues();
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(["/login"]);
    },error=>{
    });
  }

  policyDetails() {
    this.router.navigate(['/policy-details/' + this.policyNumber]);
    return false;
  }

  payment(){
    this.router.navigate(['/payment-history/'+ this.policyNumber])
    return false;
  }

  policyDocuments(){
    this.router.navigate(['/policy-documents/'+ this.policyNumber])
    return false;
  }

  GetCardDetailById(cardid:number)
  {
    this.paymentService.GetCardDetailsById(this.policyNumber,cardid).subscribe(data => {
      console.log(data)
      loadingServiceHide('dvPaymentHistory');
      if(data.status==401)
      {
        Swal.fire('', 'Authentication Failed', 'warning')
        return false
      }
      if (data.status == 200) 
      {
        debugger;
        if (data.data != null) 
        {
         this.ccDetailForm.ccName = data.data.ccName;
         this.ccDetailForm.ccNumber = data.data.ccNumber;
         this.ccDetailForm.ccCVV = "";
         this.ccDetailForm.ccExpMonth = data.data.ccExpMonth;
         this.ccDetailForm.ccExpYear = data.data.ccExpYear;
         this.ccDetailForm.cardType = data.data.cardType;
          loadingServiceHide('dvPaymentHistory');
        }
      }
    })
  }

  addCCDetails(){debugger;

      loadingServiceShow(1, 'dvPaymentAddCard', true);
      this.ccDetailForm.policyNumber = this.policyNumber;
      this.ccDetailForm.cardType = this.getCardType(this.ccDetailForm.ccNumber.replace("-",""));
      this.paymentService.AddCCDetails(this.ccDetailForm).subscribe(data=>{
        debugger;
        loadingServiceHide('dvPaymentHistory');
        if(data.status==401)
        {
          Swal.fire('', 'Authentication Failed', 'warning')
          return false
        }
        if(data.status == 200){
          if(data.data != null){
            this.FindDefaultCardCount(this.policyNumber);
            this.router.navigate(["/payment-card-details"]);
          }
        }else if(data.status == 208){
            // this.message = data.data.message;
            Swal.fire('',data.message, 'warning')
            return false
            // this.alertservice.warning("Invalid user details !");
            console.log(this.message);
        }

        // if(data.data != null){
        //   if (data.status = 200){
        //     console.log(data)
        //     this.router.navigate(["/payment-card-details"]);
        //   } else if(data.status = 208){
        //     this.message = data.data.message;
        //     console.log(this.message);
        //   }
        // }
        },error =>{
          console.log(this.message);
          this.alertservice.warning("Invalid user details !");
      });
  }

   payNowRedirection(){
    this.router.navigate(["/payment-card-details"]);
    return false;
  }

  getCardType(cur_val) 
  {
    // the regular expressions check for possible matches as you type, hence the OR operators based on the number of chars
    // regexp string length {0} provided for soonest detection of beginning of the card numbers this way it could be used for BIN CODE detection also

    //JCB
    var jcb_regex = new RegExp('^(?:2131|1800|35)[0-9]{0,}$'); //2131, 1800, 35 (3528-3589)
    // American Express
    var amex_regex = new RegExp('^3[47][0-9]{0,}$'); //34, 37
    // Diners Club
    var diners_regex = new RegExp('^3(?:0[0-59]{1}|[689])[0-9]{0,}$'); //300-305, 309, 36, 38-39
    // Visa
    var visa_regex = new RegExp('^4[0-9]{0,}$'); //4
    // MasterCard
    var mastercard_regex = new RegExp('^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$'); //2221-2720, 51-55
    var maestro_regex = new RegExp('^(5[06789]|6)[0-9]{0,}$'); //always growing in the range: 60-69, started with / not something else, but starting 5 must be encoded as mastercard anyway
    //Discover
    var discover_regex = new RegExp('^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$');
    ////6011, 622126-622925, 644-649, 65


    // get rid of anything but numbers
    cur_val = cur_val.replace(/\D/g, '');

    // checks per each, as their could be multiple hits
    //fix: ordering matter in detection, otherwise can give false results in rare cases
    var sel_brand = "unknown";
    if (cur_val.match(jcb_regex)) {
        sel_brand = "JCB";
    } else if (cur_val.match(amex_regex)) {
        sel_brand = "Amex";
    } else if (cur_val.match(diners_regex)) {
        sel_brand = "Diners_club";
    } else if (cur_val.match(visa_regex)) {
        sel_brand = "Visa";
    } else if (cur_val.match(mastercard_regex)) {
        sel_brand = "Mastercard";
    } else if (cur_val.match(discover_regex)) {
        sel_brand = "Discover";
    } else if (cur_val.match(maestro_regex)) {
        if (cur_val[0] == '5') { //started 5 must be mastercard
            sel_brand = "MasterCard";
        } else {
            sel_brand = "Maestro"; //maestro is all 60-69 which is not something else, thats why this condition in the end
        }
    }

    return sel_brand;
  }

  FindDefaultCardCount(pNo)
  {
    debugger;
    this.paymentService.GetCCCardCount(pNo).subscribe(data => {
      if (data["status"] == 200) {
       var test = data.data;
       if(test == 1)
       {
        this.ccCount = true;        
       }
       else{
        this.ccCount = false;        

       }
      }
    }, error => {
      Swal.fire("", "Something went wrong while getting comment!", "error")
    });
  }
}
