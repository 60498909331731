import { Component, OnInit } from '@angular/core';
import {LoginService} from '../../../core/services/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PartialService } from '../../partials/partial.service';

@Component({
  selector: 'app-policy-details-edit',
  templateUrl: './policy-details-edit.component.html',
  styleUrls: ['./policy-details-edit.component.css']
})
export class PolicyDetailsEditComponent implements OnInit {
  firstName:string;
  lastName:string;
  policyNumber:String;
  effectiveDate:string;
  expirationDate:String;

  constructor(
    private loginService:LoginService,
    private partialService:PartialService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(){
    this.firstName=localStorage.getItem('firstname');
    this.lastName=localStorage.getItem('lastname');
    this.policyNumber=localStorage.getItem('policynumber');
    this.effectiveDate=localStorage.getItem('effectiveDate');
    this.expirationDate=localStorage.getItem('expirationDate');
  }

  
doLogout(){
  this.loginService.logOut().subscribe(data=>{
    this.partialService.ClearPartialValues();
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(["/login"]);
  },error=>{
     console.log();
  });
}


policyDetails() {
  this.router.navigate(['/policy-details/' + this.policyNumber]);
  return false;
}

payment(){
  this.router.navigate(['/payment-history/'+ this.policyNumber])
  return false;
}

policyDocuments(){
  this.router.navigate(['/policy-documents/'+ this.policyNumber])
  return false;
}

}
