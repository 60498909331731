import { Component, OnInit } from '@angular/core';
import {LoginService} from '../../../core/services/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PartialService } from '../../partials/partial.service';
import { EPage } from 'src/app/core/enums/app.enums';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.css']
})
export class PaymentSuccessComponent implements OnInit {
  firstName:string;
  lastName:string;
  policyNumber:String;
  effectiveDate:string;
  expirationDate:String;
  installmentAmount:string;
  paidAmount:string;
  transactionNumber:string = "";
  strPayment: string = EPage[EPage.Payment].toString();
  constructor(
    private loginService:LoginService,
    private partialService:PartialService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(){
    this.firstName=localStorage.getItem('firstname');
    this.lastName=localStorage.getItem('lastname');
    this.policyNumber=localStorage.getItem('policynumber');
    this.effectiveDate=localStorage.getItem('effectiveDate');
    this.expirationDate=localStorage.getItem('expirationDate');
    this.installmentAmount = localStorage.getItem('installmentAmount');
    this.transactionNumber = localStorage.getItem('transactionId');
    this.paidAmount = localStorage.getItem('paymentAmount')!='' && localStorage.getItem('paymentAmount')!=undefined && parseFloat(localStorage.getItem('paymentAmount').replace(",","")) > 0 ?localStorage.getItem('paymentAmount').replace(",",""):this.installmentAmount;
  }

  doLogout(){
    this.loginService.logOut().subscribe(data=>{
      this.partialService.ClearPartialValues();
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(["/login"]);
    },error=>{
       console.log();
    });
  }

  policyDetails() {
    this.router.navigate(['/policy-details/' + this.policyNumber]);
    return false;
  }

  payment(){
    this.router.navigate(['/payment-history/'+ this.policyNumber])
    return false;
  }

  policyDocuments(){
    this.router.navigate(['/policy-documents/'+ this.policyNumber])
    return false;
  }
}
