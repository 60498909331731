import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../core/services/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { IDocumentUrlModel, IPolicyDetails } from '../../../core/interfaces/app.interface';
import { PolicyService } from '../../../core/services/policy.service';
import { DatePipe } from '@angular/common';
import { EPage } from 'src/app/core/enums/app.enums';
import { PartialService } from '../../partials/partial.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { isNullOrUndefined } from 'util';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

declare function loadingServiceShow(zindex, id, flag);
declare function loadingServiceHide(id);

@Component({
  selector: 'app-policy-documents',
  templateUrl: './policy-documents.component.html',
  styleUrls: ['./policy-documents.component.css']
})
export class PolicyDocumentsComponent implements OnInit {
  documents: IDocumentUrlModel[] = [];
  policyDetails: IPolicyDetails = <IPolicyDetails>{};
  policyNumber: string;
  effectiveDate: string;
  expirationDate: String;
  todayDate: String;
  firstName: string;
  lastName: string;
  lastLoginDate: string;
  quoteId : string;
  strPayment: string = EPage[EPage.Document].toString();
  policyURL: any;
  constructor(
    private loginService: LoginService,
    private partialService:PartialService,
    private alertservice: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private policyService: PolicyService,
    public datepipe: DatePipe,
  ) { }

  ngOnInit() {
    this.policyNumber = localStorage.getItem('policynumber');
    this.firstName = localStorage.getItem('firstname');
    this.lastName = localStorage.getItem('lastname');
    this.lastLoginDate = localStorage.getItem('lastLoginDate');
    this.getQuoteBypolicy();
    this.getDocuments(this.policyNumber);
    this.getPolicyNumberByDetails(this.policyNumber);
  }


  getPolicyNumberByDetails(policyNumber: string) 
  {
    loadingServiceShow(1, 'dvDocument', true);
    this.policyService.PolicyDetailsByPolicyNumber(policyNumber).subscribe(data => {
      loadingServiceHide('dvDocument');
      if(data.status==401)
      {
        Swal.fire('', 'Authentication Failed', 'warning')
        return false
      }
      if (data.data != null) {
        if (data.status == 200) 
        {
            this.policyDetails=data.data;
          var effectiveDate = this.datepipe.transform(data.data["effectiveDate"], 'M/yy');
          data.data["effectiveDate"] = effectiveDate;
          this.effectiveDate = effectiveDate;
          var expirationDate = this.datepipe.transform(data.data["expirationDate"], 'M/yy');
          data.data["expirationDate"] = expirationDate;
          this.expirationDate = expirationDate;
          var paymentDate = this.datepipe.transform(data.data["installmentDueDate"], 'M/d/yy');
          this.partialService.policyStatus = data.data.status;
          if(data!=null && data.data!=null && !isNullOrUndefined(data.data.status) && data.data.status!='')
          { 
            localStorage.setItem("policyStatus",data.data.status);
            if(data.data.status.toLowerCase().includes("cancelled"))
            {
              this.partialService.isCancel = true;
            }
            else if(data.data.status.toLowerCase().includes("active"))
            {
              this.partialService.isActive = true;
            }
            else if(data.data.status.toLowerCase().includes("notice"))
            {
              this.partialService.isNotice = true;
            }
          }
        }
      }
      else{
        localStorage.clear();
        sessionStorage.clear();
      this.router.navigate(["/login"]);
      }
      loadingServiceHide('dvDocument');
    }, error => {
      console.log();
      loadingServiceHide('dvDocument');
    })
    
  }

  getDocuments(policyNumber: string) {
    this.policyService.PolicyDocumentByPolicyNumber(policyNumber).subscribe(data => 
      {
        loadingServiceHide('dvDocument');
        if(data.status==401)
        {
          Swal.fire('', 'Authentication Failed', 'warning')
          return false
        }
        if (data.data != null) {
        if (data.status == 200 && data.data.length > 0)
        {
            var documentDate = this.datepipe.transform(data.data[0]["documentDate"], 'M/d/yy');
            data.data[0]["documentDate"] = documentDate;
            this.todayDate = documentDate;
            data.data.forEach(el => {
              el.documentUrl = environment.urls.apiBaseURL+"a/file/"+btoa(el.documentUrl);
            });
            this.documents = data.data;
        }
        else
        {
          this.alertservice.warning("No Documents found!");
        }
        loadingServiceHide('dvDocument');
      }
    }, error => {
      this.alertservice.warning("Error while fetching Documents!");
      loadingServiceHide('dvDocument');
    });
  }


  doLogout() {
    this.loginService.logOut().subscribe(data => {
      this.partialService.ClearPartialValues();
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(["/login"]);
    }, error => {
      console.log();
    });
  }

  download(){
    this.policyService.getPolicyPaperWorkFile(this.quoteId ,"POLICY").subscribe(result => {
      debugger;
      if(result.status==200){
        const binaryString = atob(result["data"]);
        const byteArray = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
          byteArray[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = this.quoteId.toString()+ "_SignedPaperWork";
        a.click();
        URL.revokeObjectURL(url); 
      }
    });
  }

  getQuoteBypolicy(){
    debugger
    this.policyService.GetQuoteByPolicy(this.policyNumber).subscribe(data =>{
      if(data.status==401)
      {
        Swal.fire('', 'Authentication Failed', 'warning')
        return false
      }
      else if (data.status == 200 && data.data.length > 0){
        this.quoteId = data["data"];
      }
      else
      {
        this.alertservice.warning("No Documents found!");
      }

    });
  }

  policyDetail() {
    this.router.navigate(['/policy-details/' + this.policyNumber]);
    return false;
  }

  payment() {
    this.router.navigate(['/payment-history/' + this.policyNumber])
    return false;
  }

  policyDocuments() {
    this.router.navigate(['/policy-documents/' + this.policyNumber])
    return false;
  }

}
