import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthGuardService } from './auth-guard.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate 
{
  constructor(private authService:AuthGuardService,private router:Router){

  }

  canActivate(): boolean 
    {
      if(!this.authService.isLoggedIn())
      {
        this.router.navigate(["/login"]);
      }
      return this.authService.isLoggedIn();
    }
  
}
