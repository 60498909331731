import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { EPage } from '../../core/enums/app.enums'

@Injectable({
  providedIn: 'root'
})
export class PartialService {

  lastLoginDate: string;
  policyNumber: string;
  policyStatus: string;
  cancelDate:string;
  isCancel:boolean =false;
  isActive:boolean = false;
  isNotice:boolean = false;
  defaultCardId:number = 0;
  constructor() {  }

    CheckValue(_page) 
    {
      if (_page == EPage[EPage.PolicyDetail].toString() || _page == EPage[EPage.Payment].toString() || _page == EPage[EPage.PaymentCard].toString() || _page == EPage[EPage.Document].toString())
      {
        return true;
      }
      else
      {
        return false;
      }
    
    }

    ClearPartialValues()
    {
      this.policyStatus = "";
      this.policyNumber = "";
      this.isActive = false;
      this.isCancel = false;
      this.isNotice = false;
      this.lastLoginDate = "";
      this.cancelDate = "";
    }
}
