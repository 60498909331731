import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from '../partials/header/header.component';
import {FooterComponent} from '../partials/footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AlertComponent } from '../partials/alert/alert.component';
import { ContactDetailsComponent } from './contact-details/contact-details.component';
import { SubHeaderComponent } from './sub-header/sub-header.component';

@NgModule({

    declarations:[
        HeaderComponent,
        FooterComponent,
        AlertComponent,
        ContactDetailsComponent,
        SubHeaderComponent,
    ],
    exports:[
        HeaderComponent,
        FooterComponent,
        AlertComponent,
        ContactDetailsComponent,
        SubHeaderComponent,
    ],
    imports:[
        CommonModule,
        RouterModule,
        FormsModule,
    ],
    providers:[DatePipe],
    entryComponents:[],

})

export class PartialsModule { }