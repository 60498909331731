import { Component, HostListener, OnInit } from '@angular/core';
import { BsModalRef,BsModalService }   from 'ngx-bootstrap/modal';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
declare function loadIframe(id, url): any;
declare var $: any;

// Declare loader function
declare function loadingServiceShow(zindex, id, flag);
declare function loadingServiceHide(id);
@Component({
  selector: 'app-payment-sign-document',
  templateUrl: './payment-sign-document.component.html',
  styleUrls: ['./payment-sign-document.component.css']
})
export class PaymentSignDocumentComponent implements OnInit {

  signURL:string = "";
  urlSafe: SafeResourceUrl;
  height: any;
  constructor(private bsModalRef: BsModalRef,private modalService:BsModalService,private sanitizer: DomSanitizer,private router:Router) { }

  @HostListener("window:message", ["$event"])
  SampleFunction($event: MessageEvent) 
  {
    loadingServiceHide('ifrmDisplayReport');
    if ($event.data != null && $event.data != undefined && $event.data.event_id  == "url-call-back") 
    {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/payment-card-details'], { queryParams: {s: 'completed' } }));
    }
  }
  
  ngOnInit(): void {
    loadingServiceShow(9999,'ifrmDisplayReport',true);
    this.signURL = <any>this.modalService.config.initialState["signURL"];
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.signURL);
    this.height = window.innerHeight + 150;
    loadIframe('ifrm', this.signURL);

    setTimeout(function(){
loadingServiceHide('ifrmDisplayReport');
    },18000);
  }
  

  
  

  close()
  {
    this.bsModalRef.hide();
  }
}
