<body>
    <header>
        <div class="container">
            <div class="header-inner d-flex row">
                <div class="header-logo">
                    <a href="#"><img src="../../../../assets/images/excepsure-logo.png"></a>
                </div>
            </div>
        </div>
    </header>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-12 login-scr-cvr">
                    <div class="col-md-5 col-12 float-left login-form text-white text-center">
                        <h1 class="welcome-text">Welcome to Our Customer Portal</h1>
                        <span class="fs-25">Please Choose a Password</span>
                        <app-alert></app-alert>
                        <form method="post" [formGroup]='IsCustomer' novalidate (ngSubmit)="postCustomerDetail()">
                            <div class="form-field">
                                <label class="fs-25">Password</label>
                                <input [(ngModel)]="customerDetails.password" formControlName="txtpassword" class="form-control" type="password" name="" id="txtpassword">
                                <div *ngIf="txtpassword.invalid && (txtpassword.dirty || isCustomerDetail)" class="alert alert-danger">
                                    <span *ngIf="txtpassword.errors.required">
                                      Password is required.
                                    </span>
                                </div>
                            </div>
                            <div class="form-field">
                                <label class="fs-25">Confirm Password</label>
                                <input [(ngModel)]="customerDetails.confirmPassword" formControlName="txtconfirmpassword" class="form-control" type="password" name="" id="txtconfirmpassword">
                                <div *ngIf="txtconfirmpassword.invalid && (txtconfirmpassword.dirty || isCustomerDetail)" class="alert alert-danger">
                                    <span *ngIf="txtconfirmpassword.errors.required">
                                      Confirm Password is required.
                                    </span>
                                    <span *ngIf="txtconfirmpassword.hasError('invalid')">
                                        Password and Confirm password not match.
                                    </span>
                                </div>
                            </div>
                            <div class="form-field-submit">
                                <input type="submit" name="" value="Submit">
                            </div>
                        </form>
                    </div>
                    <div class="col-md-6 col-12 float-right login-screen-disc text-white px-0">
                        <h2 class="big-title">Online Access Benefits</h2>
                        <ul class="fs-25">
                            <li>View your policy</li>
                            <li>Go paperless</li>
                            <li>Get ID cards</li>
                            <li>Pay your bill</li>
                            <li>View account balance and more</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <app-footer></app-footer>
</body>