import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ICustomerDetailModel } from 'src/app/core/interfaces/app.interface';
import { AlertService } from 'src/app/core/services/alert.service';
import { CustomerService } from 'src/app/core/services/customer.service';
import {md5} from '../../../core/utilities/md5';
// Declare loader function
declare function loadingServiceShow(zindex, id, flag);
declare function loadingServiceHide(id);

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  customerDetails: ICustomerDetailModel = <ICustomerDetailModel>{};
  frmForgotPassword:FormGroup;
  isCustomerDetail: boolean = false;
  isVerification:boolean = false;
  isRedirected:boolean = false;
  policyNumber:string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private customerService:CustomerService,
    public datepipe: DatePipe,
    private formBuilder:FormBuilder,
    private alertservice:AlertService
  ) { }

  ngOnInit()
  {
    this.frmForgotPassword =this.formBuilder.group({
      txtpolicynumber:[null,[Validators.required]],
    })
  }

  get txtpolicynumber()    {return this.frmForgotPassword.get('txtpolicynumber');}

sendForgotPasswordLink(txtpolicynumber:any)
{
  if(this.frmForgotPassword.valid)
  {
    loadingServiceShow(1, 'dvForgotPassword', true);
    let policyNumber = txtpolicynumber.value;
    this.customerService.sendForgotPasswordLink(policyNumber).subscribe(data=>{
    if (data.status == 400 || data.status == 401) {
      this.alertservice.warning(data.message);
      localStorage.setItem('alertmsg', data.message);
    }
    else {
      this.alertservice.success('Mail Send Successfully');
      localStorage.setItem('alertmsg', 'Data saved successfully');
      this.router.navigate(["/verification-forgot-password/"+policyNumber]);
    }
    loadingServiceHide('dvForgotPassword');
    },
    error => {
      this.alertservice.error('Invalid Policy Number Try Again!');
      loadingServiceHide('dvForgotPassword');
    });
  }
  else
  {
    this.alertservice.warning('Please fill required details');
    return false;
  }
}
  
}
