import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {ISignUp,ILoginModel} from '../../core/interfaces/app.interface';
import {Observable} from 'rxjs/observable';
import { of } from 'rxjs/observable/of'
import {ErrorObservable} from 'rxjs/observable/ErrorObservable';
import {catchError} from 'rxjs/operators';
import {AppConstants} from '../utilities/app.constant';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
  })
export class LoginService{
    constructor(
        private http: HttpClient,
    ){}

    signUp(Signup: ISignUp): Observable<any> {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        };
        return this.http.post(environment.urls.apiBaseURL + 'v1/sign-up', Signup, httpOptions)
          .pipe(catchError(this.handleError));
      }

      PolicyDetailsByQuote(quote: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json'
            })
          };
        return this.http.get(environment.urls.apiBaseURL + 'v1/sign-up/' + quote,httpOptions)
            .pipe(catchError(this.handleError));
    }

      login(loginDetails:ILoginModel):Observable<any>{
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        };
        return this.http.post(environment.urls.apiBaseURL+'v1/login',loginDetails,httpOptions)
        .pipe(catchError(this.handleError));
      }

     logOut():Observable<any>{
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return this.http.post(environment.urls.apiBaseURL+'v1/logout',null,httpOptions)
      .pipe(catchError(this.handleError));
     }

     checkRenewal(Signup: ISignUp):Observable<any>{
       const httpOptions = {
         headers: new HttpHeaders({
           'content-Type':'application/json'
         })
       };
       return this.http.post(environment.urls.apiBaseURL + 'v1/check-policy', Signup, httpOptions)
            .pipe(catchError(this.handleError));
     }

    private handleError(error: HttpErrorResponse) {
        if (error.status == 401) {
            return new ErrorObservable(error.error.message);
        }
        console.log('Status:' + error.status + ',' + error.error.message);
        return new ErrorObservable(error.error.message);
    };
}