<body>
    <header>
        <div class="container" data-loader="dvDocument">
            <div class="header-inner d-flex row">
                <div class="header-logo">
                    <a href="#"><img src="../../../../assets/images/excepsure-logo.png"></a>
                </div>
                <nav class="navbar navbar-expand-md tabing_navigatiin navbar-dark">
                    <button class="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#collapsibleNavbar">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="collapsibleNavbar">
                        <ul class="navbar-nav">
                            <li class="nav-item"><a class="nav-link pointer" (click)="policyDetail()">Policy Details</a></li>
                            <li class="nav-item"><a class="nav-link pointer" (click)="payment()">Payments</a></li>
                            <li class="nav-item active"><a class="nav-link pointer" (click)="policyDocuments()">Policy
                                    Documents</a></li>
                        </ul>
                    </div>
                </nav>

                <div class="right_header d-flex">
                    <div class="my-account">
                        <div class="dropdown">
                            <div class="dropdown-toggle" id="menu1" data-toggle="dropdown"><span class="user-icon"><img
                                        src="../../../../assets/images/MyAccount.svg"></span> <span
                                    class="mobile_rob">{{firstName}} {{lastName}}</span></div>
                            <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                                <!-- <li><a href="#"><span class="dw-icon"><img src="../../../../assets/images/settings.svg"></span> Settings</a></li> -->
                                <li><a (click)="doLogout()"><span class="dw-icon"><img
                                                src="../../../../assets/images/logout.svg"></span> Sign Out</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section>
        <div class="container pd-container">
            <div class="row">
                <div class="col-12 policy-container">
                       <app-sub-header [fromPage]="strPayment"></app-sub-header>
                       <app-alert></app-alert>
                    <div class="payment-sum-panel d-flex">
                        <div class="col-md-12 px-0">
                            <div class="panel-box policy-docs">
                                <div class="box-strip">POLICY DOCUMENTS</div>
                                <div class="box-cont">
                                    <div class="panel-bordered-table">
                                        <table>
                                            <tr *ngFor="let doc of documents">
                                                <td>{{todayDate}}</td>
                                                <div *ngIf="doc.documentName=='Policy'">
                                                    <td>Signed Application</td>
                                                </div>
                                                <div *ngIf="doc.documentName=='ID Card'">
                                                    <td>ID Card {{doc.year}} {{doc.make}} {{doc.model}}</td>
                                                </div>
                                                <div *ngIf="(doc.documentName !='Policy' && doc.documentName !='ID Card')">
                                                    <td>{{doc.documentName}}</td>
                                                </div>
                                                <td *ngIf="doc.documentName != 'Policy'"><a href="{{doc.documentUrl}}" target="_blank">VIEW DOCUMENT</a></td>
                                                <td *ngIf="doc.documentName == 'Policy'"><a  class="policy-document" (click)="download()">VIEW DOCUMENT</a></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-contact-details></app-contact-details>
        </div>
    </section>
    <app-footer></app-footer>
</body>