import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../../core/services/login.service';
import { isNullOrUndefined } from 'util';
import { ISignUp } from 'src/app/core/interfaces/app.interface';
import { AlertService } from '../../../core/services/alert.service';
import { ActivatedRoute } from "@angular/router";
import { PolicyService } from '../../../core/services/policy.service';
import { DatePipe } from '@angular/common';

// Declare loader function
declare function loadingServiceShow(zindex, id, flag);
declare function loadingServiceHide(id);

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  quote: string;
  signUp: FormGroup;
  isSignUp: boolean = false;
  isFromMail: boolean = false;
  policyNumber: string;
  effectiveDate: string;
  expirationDate: String;
  signUpDetails: ISignUp = { policyNumber: null, zipCode: null, birthDate: null }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private loginservice: LoginService,
    private alertservice: AlertService,
    private route: ActivatedRoute,
    private policyService: PolicyService,
    public datepipe: DatePipe

  ) { this.route.params.subscribe(params => this.getPolicyDetailByQuote(params['quote'])) }

  ngOnInit() {
    this.signUp = this.formBuilder.group({
      txtpolicynumber: [null, [Validators.required]],
      dob: [null, [Validators.required, Validators.pattern(/^((0?[1-9]|1[012])[/](0?[1-9]|[12][0-9]|3[01])[/](19|20)?[0-9]{2})*$/)]],
      txtzipcode: [null, Validators.required]
    })
  }

  get txtpolicynumber() { return this.signUp.get('txtpolicynumber'); }
  get dob() { return this.signUp.get('dob'); }
  get txtzipcode() { return this.signUp.get('txtzipcode'); }

  getPolicyDetailByQuote(quote: string) {
    if (quote != null && quote != undefined && quote != "") 
    {
      this.loginservice.PolicyDetailsByQuote(quote).subscribe(data => {
        if (data.data != null) {
          if (data.status = 200) {
            this.isFromMail = true;
            localStorage.setItem('policynumber', data.data.policyNumber);
            localStorage.setItem('firstname', data.data.firstName);
            localStorage.setItem('lastname', data.data.lastName);
            localStorage.setItem('isFromMail', "true");
            this.signUpDetails.policyNumber = data.data.policyNumber;
            this.signUpDetails.birthDate = data.data.birthDate;
            this.signUpDetails.zipCode = data.data.zipCode;
          }
        } else {
          if (data.status = 401 && data.message == "Already used this policy number for signup. Please login for access your document.") {
            this.router.navigate(["/login"]);
          }
        }
      })
    }
  }

  signUpSubmit() {
    if (this.signUp.valid) {
      loadingServiceShow(1, 'dvRegistration', true);
      this.loginservice.signUp(this.signUpDetails).subscribe(data => {
        loadingServiceHide('dvRegistration');
        if (data.status == 400) {
          this.alertservice.warning(data.message);
        }
        if (data.status == 201) {
          this.alertservice.warning("Already Registered");
        }
        if (data.data != null) {
          if (data.status = 200) {
            localStorage.setItem('policynumber', data.data.policyNumber);
            localStorage.setItem('firstname', data.data.firstName);
            localStorage.setItem('lastname', data.data.lastName);
            this.policyNumber = data.data.policyNumber;
            this.router.navigate(["/password-confirmation"]);
          }
        }
      }, error => {
        loadingServiceHide('dvRegistration');
        console.log();
        this.alertservice.warning("Invalid Details!");
      }

      )
    }
    else {
      this.alertservice.warning('Please fill all required details');
      return false;
    }
  }

}
