import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { LoginService } from '../../../core/services/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PaymentService } from '../../../core/services/payment.service';
import { AlertService } from '../../../core/services/alert.service';
import { IPolicyDetails, ICCDetailModel } from '../../../core/interfaces/app.interface';
import { isNullOrUndefined, isNull } from 'util';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

import { EPage } from '../../../core/enums/app.enums';
import { PaymentSignDocumentComponent } from '../payment-sign-document/payment-sign-document.component';
import { DatePipe } from '@angular/common';
import { PartialService } from '../../partials/partial.service';
import { PolicyService } from 'src/app/core/services/policy.service';
import { PaymentInstallmentComponent } from '../payment-installment/payment-installment.component';
import Swal from 'sweetalert2';
declare var $: any;
// Declare loader function
declare function loadingServiceShow(zindex, id, flag);
declare function loadingServiceHide(id);

@Component({
  selector: 'app-payment-card-details',
  templateUrl: './payment-card-details.component.html',
  styleUrls: ['./payment-card-details.component.css']
})
export class PaymentCardDetailsComponent implements OnInit {
  firstName: string;
  lastName: string;
  policyNumber: string;
  effectiveDate: string;
  expirationDate: String;
  dueAmount: number;
  premiumAmount: number;
  cardList: ICCDetailModel[] = [];
  installmentAmount: string;
  IsDefaultCard: boolean = false;
  IsCardExist: boolean;
  IsValidAmount: boolean = true;
  IsCancelPolicy: boolean = false;
  IsDocumentSigned: boolean = false;
  isPaid: boolean = false;
  ccFee: number = 6;
  public bsModalRef: BsModalRef;
  policyDetailsModel: IPolicyDetails = <IPolicyDetails>{};
  DefaultCard: ICCDetailModel = <ICCDetailModel>{};
  myDate = new Date();
  displayDate: string;
  isRecurring: boolean;
  isPaymentAllowed: boolean;
  ccCount : boolean = false;
  totalAmount : number;
  strPayment: string = EPage[EPage.PaymentCard].toString();
  constructor(
    private loginService: LoginService,
    public partialService: PartialService,
    private policyService: PolicyService,
    private router: Router,
    private route: ActivatedRoute,
    private PaymentService: PaymentService,
    private alertservice: AlertService,
    private modalService: BsModalService,
    public datepipe: DatePipe
  ) {

  }
  ngOnInit() {
    debugger;
    if (this.route.snapshot.queryParamMap.get("s") != null && this.route.snapshot.queryParamMap.get("s") != undefined) {
      this.isPaid = true;
      let amount = localStorage.getItem("currentPaidAmount");
      if (!isNullOrUndefined(amount) && parseFloat(amount) > 0) {
        debugger;
        this.processPayment(amount);
      }
    }
    this.alertservice.clearAlertMessage();
    loadingServiceShow(1, 'dvPaymentCardDetail', true);
    this.firstName = localStorage.getItem('firstname');
    this.lastName = localStorage.getItem('lastname');
    this.policyNumber = localStorage.getItem('policynumber');
    this.effectiveDate = localStorage.getItem('effectiveDate');
    this.expirationDate = localStorage.getItem('expirationDate');
    this.installmentAmount = localStorage.getItem('installmentAmount') ;
    this.displayDate = this.datepipe.transform(this.myDate, 'MM/dd/yyyy');
    if (!isNullOrUndefined(this.installmentAmount) && Number(this.installmentAmount) > 0) {
      var amount = parseFloat(this.installmentAmount).toFixed(2);
      this.installmentAmount = parseFloat(amount).toString();
    }
    else {
      if (Number(this.installmentAmount) == 0) {
        this.IsValidAmount = false;
      }
      var defaultAmount = parseFloat("0").toFixed(2).toString();
      this.installmentAmount = defaultAmount;
    }
    if (!isNullOrUndefined(sessionStorage.getItem('dueAmount'))) {
      this.installmentAmount = sessionStorage.getItem('dueAmount');
    }

    if (!isNullOrUndefined(localStorage.getItem('isPostable')) && localStorage.getItem('isPostable').toLowerCase() == "false") {
      this.IsValidAmount = false;
    }

    if (!isNullOrUndefined(localStorage.getItem('policyStatus')) && localStorage.getItem('policyStatus').toLowerCase().includes("cancelled") && localStorage.getItem('isPostable').toLowerCase() == "true") {
      this.IsCancelPolicy = true;
      this.partialService.isCancel = true;
    }

    if (localStorage.getItem('isRecurring') == "false") {
      this.DefaultCard.isRecurring = false;
    } else {
      this.DefaultCard.isRecurring = true;
    }
    this.getAllCards();
    this.getPolicyNumberByDetails(this.policyNumber);
  this.FindDefaultCardCount(this.policyNumber);
  }

  getAllCards() {
    loadingServiceShow(1, 'dvPaymentCardDetail', true);
    this.PaymentService.GetAllCards(this.policyNumber).subscribe(data => {
      if(data.status==401)
      {
        Swal.fire('', 'Authentication Failed', 'warning')
        return false
      }
      if (data.data != null) {
        
        if (data.status == 200) {
          if (!isNullOrUndefined(data.data.ccList) && data.data.ccList.length > 0) {
            this.cardList = data.data.ccList;

            for (var val of data.data.ccList) {
              this.IsCardExist = true;
              this.IsDefaultCard = false;
              if (val.isDefault == true) {
                this.DefaultCard.ccName = val.ccName;
                this.DefaultCard.ccExpMonth = val.ccExpMonth;
                this.DefaultCard.ccExpYear = val.ccExpYear;
                this.DefaultCard.ccNumber = val.ccNumber;
                this.DefaultCard.isDefault = val.isDefault;
                this.DefaultCard.cardType = val.cardType;
                // this.DefaultCard.ccDbId = val.id;
                this.DefaultCard.id = val.id;

              }
              else if (this.cardList.length == 1 && !isNullOrUndefined(localStorage.getItem("recurring") && localStorage.getItem("recurring").toString() == "true")) {
                this.IsDefaultCard = true;
              }
            }
          }
          else {
            this.addNewCard(); // redirect to new card screen
          }
        }
        else if (data.data.Message.includes("No Card")) {
          this.addNewCard(); // redirect to new card screen
        }
        else {
          this.IsCardExist = false;
        }
      }
    }, error => {
      this.alertservice.warning("Invalid user details !");
    });
    loadingServiceHide('dvPaymentCardDetail');
  }

  doLogout() {
    this.loginService.logOut().subscribe(data => {
      this.partialService.ClearPartialValues();
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(["/login"]);
    }, error => {
      console.log();
    });
  }

  policyDetails() {
    this.router.navigate(['/policy-details/' + this.policyNumber]);
    return false;
  }

  payment() {
    this.router.navigate(['/payment-history/' + this.policyNumber])
    return false;
  }

  policyDocuments() {
    this.router.navigate(['/policy-documents/' + this.policyNumber])
    return false;
  }

  addNewCard() {
    this.router.navigate(['/payment-add-card'])
    this.FindDefaultCardCount(this.policyNumber)    ;
  }

  payNow(value) {
    localStorage.setItem("currentPaidAmount", value.toString());
    this.PaymentService.IsPaymentCaptured(this.policyNumber).subscribe(data => {
      loadingServiceHide('dvPaymentCardDetail');
      if(data.status==401)
      {
        Swal.fire('', 'Authentication Failed', 'warning')
        return false
      }
      else{
        this.isPaid == data;
      }

      if (data.data != null) {
        if (data.status == 200) {
          if (data.data == true && this.isPaid == false) {
            if (confirm("We noticed you may have already made a payment today for $" + value + ". Please verify before continuing to make this payment.")) {
              this.isPaid = true;
              this.processPayment(value);
            }
            else {
              loadingServiceHide('dvPaymentCardDetail');
              return false;

            }
          }
          else {
            this.processPayment(value);
          }
        }
        else if (data.status == 401 || data.status == 402) {
          this.alertservice.warning(data.message);
        }
      }
      loadingServiceHide('dvPaymentCardDetail');
    }, error => {
      loadingServiceHide('dvPaymentCardDetail');
      this.alertservice.warning("Payment Failed!");
    })

  }

  processPayment(amount) {
    if (this.IsCancelPolicy) // Cancel Policy With IsPostable Flag Y
    {
      this.GetDocumentSignStatus(amount); // check document status and if false than only open No Loss Document
    }
    else 
    {
      const config: ModalOptions = {
        backdrop: 'static',
        keyboard: false,
        animated: true,
        ignoreBackdropClick: true,
        class: 'modal-lg',
        initialState: {
          windowClass: '',
          headerTitle: "",
          policyNumber: isNullOrUndefined(this.policyNumber) ? localStorage.getItem('policynumber') : this.policyNumber,
          amount:parseFloat(amount)
        }
      };
      this.bsModalRef = this.modalService.show(PaymentInstallmentComponent, config); // pass modal configuration parameters
      this.bsModalRef.content.closeBtnName = 'Close';
      this.bsModalRef.content.event.subscribe(data => {
      });
      // this.policyDetailsModel.PolicyNumber = isNullOrUndefined(this.policyNumber) ? localStorage.getItem('policynumber') : this.policyNumber;
      // this.policyDetailsModel.amount = parseFloat(amount.replace(",", "")); // no need to set in policyDetailsModel
      // this.policyDetailsModel.ccfees = parseFloat("5"); // static CC Fees 
      // localStorage.setItem("paymentAmount", amount.toString()); // set payment amount in local Storage
      // loadingServiceShow(1, 'dvPaymentCardDetail', true);
      // this.PaymentService.PayPolicyInstallment(this.policyDetailsModel).subscribe(data => {
      //   if (data.data != null) {
          
      //     if (data.status = 200) {
      //       localStorage.setItem("transactionId", data.data.pgTransactionId);
      //       this.router.navigate(["/payment-success"]);
      //     }
      //     else if (data.status == 401 || data.status == 402) {
      //       this.alertservice.warning(data.message);
      //       return false;
      //     }
      //   }
      //   loadingServiceHide('dvPaymentCardDetail');
      // }, error => {
      //   loadingServiceHide('dvPaymentCardDetail');
      //   this.alertservice.warning("Payment Failed!");
      // })
    }
  }
  getPolicyNumberByDetails(policyNumber: string) {
    loadingServiceShow(1, 'dvPaymentCardDetail', true);
    this.policyService.PolicyDetailsByPolicyNumber(policyNumber).subscribe(data => {
      if (data.data != null) {
        if (data.status = 200) {
          this.policyDetailsModel = data.data;
          this.dueAmount = this.policyDetailsModel.amount;
          this.premiumAmount = this.policyDetailsModel.amount;
          this.totalAmount = this.premiumAmount + this.ccFee;
          this.isRecurring = data.data.isRecurring;
          this.isPaymentAllowed = data.data.isPaymentAllowed;
          this.partialService.policyStatus = data.data.status;
          if (data != null && data.data != null && !isNullOrUndefined(data.data.status) && data.data.status != '') {
            localStorage.setItem("policyStatus", data.data.status);
            if (data.data.status.toLowerCase().includes("cancelled")) {
              this.partialService.isCancel = true;
              let cancelDate = this.datepipe.transform(this.policyDetailsModel.cancelDate, 'MM/dd/yyyy');
              localStorage.setItem("policyCancelDate", cancelDate);
              this.partialService.cancelDate = cancelDate;
            }
            else if (data.data.status.toLowerCase().includes("active")) {
              this.partialService.isActive = true;
            }
            else if (data.data.status.toLowerCase().includes("notice")) {
              let cancelDate = this.datepipe.transform(this.policyDetailsModel.cancelDate, 'MM/dd/yyyy');
              localStorage.setItem("policyCancelDate", cancelDate);
              this.partialService.cancelDate = cancelDate;
              this.partialService.isNotice = true;
            }
          }
        }
      }
      else{
        localStorage.clear();
        sessionStorage.clear();
      this.router.navigate(["/login"]);
      }
      loadingServiceHide('dvPaymentCardDetail');
    }, error => {
      console.log();
      loadingServiceHide('dvPaymentCardDetail');

    })
  }

  openModalWithComponent(signURL: string) {
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-lg custommodal',
      initialState: {
        windowClass: '',
        headerTitle: "",
        signURL: signURL
      }
    };

    this.bsModalRef = this.modalService.show(PaymentSignDocumentComponent, config); // pass modal configuration parameters
    this.bsModalRef.content.closeBtnName = 'Close';

  }
  cardSelection(_row) {
    debugger;

    if (this.IsDefaultCard) {
      _row.isRecurring = true;
    }
    this.DefaultCard = _row;

  }

  removeCard() {
    debugger;
    loadingServiceShow(1, 'dvPaymentCardDetail', true);
    this.DefaultCard.actionName = "Remove";
    this.DefaultCard.policyNumber = this.policyNumber;
    this.PaymentService.UpdateCard(this.DefaultCard).subscribe(data => {
      if (data.status == 401) {
        debugger

        Swal.fire('', 'Authentication Failed', 'warning')
        loadingServiceHide('dvPaymentCardDetail');
        return false
      }
      else if(data.status == 416)
      {
        debugger

        Swal.fire('', data.message, 'warning')
        loadingServiceHide('dvPaymentCardDetail');
      }
      else if(data.status == 422)
      {
        debugger

        Swal.fire('', "Something went wrong in removing card!!!", 'info')
        loadingServiceHide('dvPaymentCardDetail');
      }
      if (data.data != null) {
        if (data.status == 200) {
          debugger

          this.FindDefaultCardCount(this.policyNumber);
          this.cardList = data.data.ccList;
          if (data.data != null && data.data.ccList != null && data.data.ccList.length > 0) {
            for (var val of data.data.ccList) {
              if (val.isDefault == true) {
                debugger
                this.DefaultCard.ccName = val.ccName;
                this.DefaultCard.ccExpMonth = val.ccExpMonth;
                this.DefaultCard.ccExpYear = val.ccExpYear;
                this.DefaultCard.ccNumber = val.ccNumber;
                this.DefaultCard.isDefault = val.isDefault;
                // this.DefaultCard.ccDbId = val.id;
                this.DefaultCard.id = val.id;
                loadingServiceHide('dvPaymentCardDetail');
                debugger

             //   this.ngOnInit(); // recall ngOnIt so that it'll fill card details 
              }
              loadingServiceHide('dvPaymentCardDetail');
            }
          }
          else {
            this.addNewCard(); // add new card
          }

        }
      }
    }, error => {
      this.alertservice.warning("Payment Failed!");
    })
  }

  changeDefaultCard() {
    debugger
    loadingServiceShow(1, 'dvPaymentCardDetail', true);
    this.DefaultCard.actionName = "Default";
    this.DefaultCard.policyNumber = this.policyNumber;
    
    //if(this.ccCount  > 0 )
    this.PaymentService.UpdateCard(this.DefaultCard).subscribe(data => {
      if (data.status == 401) {
        Swal.fire('', 'Authentication Failed', 'warning')
        loadingServiceHide('dvPaymentCardDetail');

        return false
      }
      if (data.data != null) {
        if (data.status == 200) {
          this.cardList = data.data.ccList;
          for (var val of data.data.ccList) {
            if (val.isDefault == true) {
              this.DefaultCard.ccName = val.ccName;
              this.DefaultCard.ccExpMonth = val.ccExpMonth;
              this.DefaultCard.ccExpYear = val.ccExpYear;
              this.DefaultCard.ccNumber = val.ccNumber;
              this.DefaultCard.isDefault = val.isDefault;
              // this.DefaultCard.ccDbId = val.id;
              this.DefaultCard.id = val.id;
              loadingServiceHide('dvPaymentCardDetail');

            }
            else if (this.cardList.length == 1) {
              this.DefaultCard.isRecurring = true;
              localStorage.setItem('isRecurring', "true");
              loadingServiceHide('dvPaymentCardDetail');

            }
          }
        }
      }
    }, error => {
      this.alertservice.warning("Payment Failed!");
      loadingServiceHide('dvPaymentCardDetail');
    })
    //else
    // {
    //   Swal.fire('', 'You Can not set is default because any default card not exist', 'info')
    // }
  }

  setupRecurringOption() {
    this.DefaultCard.policyNumber = this.policyNumber;
    this.PaymentService.changeRecurringOption(this.DefaultCard).subscribe(data => {
      console.log(data)
      if(data.status==401)
      {
        Swal.fire('', 'Authentication Failed', 'warning')
        return false
      }
      if (data.status == 200) {
        if (data.data == "True") {
          this.DefaultCard.isRecurring = true;
          localStorage.setItem('isRecurring', "true");
        } else {
          this.DefaultCard.isRecurring = false;
          localStorage.setItem('isRecurring', "false");
        }
      }
    }, error => {
      this.alertservice.warning("Payment Failed!");
    })
  }

  GetDocumentSignStatus(amount: string) {
    this.PaymentService.GetDocumentStatus(this.policyNumber).subscribe(data => {
      debugger;
      if(data.status == 401)
      {
        Swal.fire('', 'Authentication Failed', 'warning')
        return false
      }
      if (data.status == 200) {
        if (data.data == true) {
          this.ProcessPayment(amount);
          //function
          this.IsDocumentSigned = true;
        } else {
          this.LoadNoLossModal();
        }
      }
    }, error => {
      // do nothing
    })
    return this.IsDocumentSigned;
  }

  LoadNoLossModal() {
    let cancelDate = localStorage.getItem("policyCancelDate");
    this.PaymentService.GetSignURL(this.policyNumber, cancelDate).subscribe(data => {
      if(data.status==401)
      {
        Swal.fire('', 'Authentication Failed', 'warning')
        return false
      }
      if (data.data != null) {
        if (data.status = 200) {
          this.openModalWithComponent(data.data.documentUrl);
        }
        else if (data.status = 401) {
          this.alertservice.warning(data.message);
        }
      }
    }, error => {
      this.alertservice.warning("Unable to Get Signing URL Try Again!");
    })
  }

  ProcessPayment(amount: string) {
    this.policyDetailsModel.PolicyNumber = this.policyNumber;
    this.policyDetailsModel.amount = parseFloat(amount);
    localStorage.setItem("paymentAmount", this.policyDetailsModel.amount.toString()); // set payment amount in local Storage
    this.policyDetailsModel.ccfees = parseFloat("6"); // static CC Fees 
    this.policyDetailsModel.CCId = 0;// every time default card will considered
    loadingServiceShow(1, 'dvPaymentCardDetail', true);
    this.PaymentService.PayPolicyInstallment(this.policyDetailsModel).subscribe(data => {
      loadingServiceHide('dvPaymentCardDetail');
      if(data.status==401)
        {
          Swal.fire('', 'Authentication Failed', 'warning')
          return false
        }
      if (data.data != null) {
        if (data.status == 200) {
          this.isPaid = false; // reset payment 
          localStorage.setItem("transactionId", data.data.pgTransactionId);
          this.router.navigate(["/payment-success"]);
        }
        else if (data.status == 401 || data.status == 402) {
          this.alertservice.warning(data.message);
        }
      }
      loadingServiceHide('dvPaymentCardDetail');
    }, error => {
      loadingServiceHide('dvPaymentCardDetail');
      this.alertservice.warning("Payment Failed!");
      this.router.navigate(["/payment-card-details"]);
    })
  }
  CheckInitialAmount() {
    var value = parseFloat("0").toFixed(2).toString();
    if (isNullOrUndefined($("input.custom-text").val()) || Number($("input.custom-text").val()) == 0) {
      $(".custom-text").val(value);
      this.IsValidAmount = false;
    }
  }
  ValidateAmount(event: any) {
    var duedate;
    var d;
    var todayDate = new Date();

    if (!isNullOrUndefined(localStorage.getItem("paymentDueDate"))) {
      d = Date.parse(localStorage.getItem("paymentDueDate"));
      duedate = new Date(d);
    }

    if (event.target.value != "" && event.target.value != undefined && Number(event.target.value.trim()) > 0) {
      debugger;
      var value = parseFloat(event.target.value).toFixed(2);
      this.totalAmount = parseFloat(value) + parseFloat(this.ccFee.toFixed(2));
      this.premiumAmount =  parseFloat(value);

      $(".custom-text").val(value);

    }
    else if (event.target.value != "" && event.target.value != undefined && Number(event.target.value.trim()) < 0) {
      this.IsValidAmount = false;
      this.alertservice.warning("Enter valid payment amount!");
      return false;
    }
    else {
      this.IsValidAmount = false;
      this.CheckInitialAmount();
      return false;
    }

    if (duedate != "" && duedate != undefined) {
      if (duedate > todayDate) {
        if (this.partialService.policyStatus.toLowerCase().includes("active")) {
          this.IsValidAmount = true;
        }
        else {
          this.IsValidAmount = false;
        }
      }
      else if (duedate <= todayDate) // due date = today date than entered amount should be >= with installment amount
      {
        var value = parseFloat(event.target.value).toFixed(2);
        var installmentAmt = parseFloat(this.installmentAmount).toFixed(2);
        this.premiumAmount =  parseFloat(value);

        if (!isNullOrUndefined(event.target.value) && parseFloat(event.target.value) > 0 && parseFloat(value) < parseFloat(installmentAmt)) {
          this.IsValidAmount = false;
          this.alertservice.warning("Enter Amount greater than $" + parseFloat(this.installmentAmount).toFixed(2).toString());
        }
        else {
          this.IsValidAmount = true;
        }

      }
      // Validate IsPostable flag
      if (localStorage.getItem('isPostable').toLowerCase() == "false") {
        this.IsValidAmount = false;
      }

    }

    if (this.IsValidAmount) {
      this.alertservice.clearAlertMessage();
    }
  }

   FindDefaultCardCount(pNo)
  {
    debugger;
    this.PaymentService.GetCCCardCount(pNo).subscribe(data => {
      if (data["status"] == 200) {
       var test = data.data;
       if(test == 1)
       {
        this.ccCount = true;        
       }
       else{
        this.ccCount = false;        

       }
      }
    }, error => {
      Swal.fire("", "Something went wrong while getting comment!", "error")
    });
  }
}
