import { Component, Input, OnInit } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { Router } from '@angular/router';
import { PartialService } from '../partial.service';
import { EPage } from 'src/app/core/enums/app.enums';


@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.css']
})
export class SubHeaderComponent implements OnInit {
  lastLoginExist: Boolean = false;
  lastLoginDate: string;
  policyNumber: string;
  policyStatus: string;
  isStatusExist: boolean = true;
  policyCancelDate: string;
  headerTitle: string = "Policy Information";
  @Input('fromPage') fromPage: string;

  constructor(private router: Router,
    public partialService: PartialService) { }

  ngOnInit(): void {
    let currentPage = this.fromPage;
    if (!isNullOrUndefined(currentPage) && currentPage.toString() == EPage[EPage.PaymentCard].toString()) {
      this.headerTitle = "Payment Information";
    }
    this.policyNumber = localStorage.getItem('policynumber');
    this.lastLoginDate = localStorage.getItem('lastLoginDate');
    if (!this.lastLoginDate.includes("01/01/0001")) {
      this.lastLoginExist = true;
    }
    else {
      this.lastLoginExist = false;
    }
    this.isStatusExist = this.partialService.CheckValue(currentPage);
    this.policyCancelDate = this.partialService.isCancel || this.partialService.isNotice ? this.partialService.cancelDate : "";
  }

}
