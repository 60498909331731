<body>
    <header>
        <div class="container" data-loader="dvPaymentAddCard">
            <div class="header-inner d-flex row">
                <div class="header-logo">
                    <a href="#"><img src="../../../../assets/images/excepsure-logo.png"></a>
                </div>
                <nav class="navbar navbar-expand-md tabing_navigatiin navbar-dark">
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
					    <span class="navbar-toggler-icon"></span>
					 </button>
                    <div class="collapse navbar-collapse" id="collapsibleNavbar">
                        <ul class="navbar-nav">
                            <li class="nav-item"><a class="nav-link" (click)="policyDetails()">Policy Details</a></li>
                            <li class="nav-item active"><a class="nav-link" (click)="payment()">Payments</a></li>
                            <li class="nav-item"><a class="nav-link" (click)="policyDocuments()">Policy Documents</a></li>
                        </ul>
                    </div>
                </nav>

                <div class="right_header d-flex">
                    <div class="my-account">
                        <div class="dropdown">
                            <div class="dropdown-toggle" id="menu1" data-toggle="dropdown"><span class="user-icon"><img src="../../../../assets/images/MyAccount.svg"></span> <span class="mobile_rob">{{firstName}} {{lastName}} </span></div>
                            <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                                <!-- <li><a href="#"><span class="dw-icon"><img src="../../../../assets/images/settings.svg"></span> Settings</a></li> -->
                                <li><a (click)="doLogout()"><span class="dw-icon"><img src="../../../../assets/images/logout.svg"></span> Sign Out</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section>
        <div class="container pd-container">
            <div class="row">
                <div class="col-12 policy-container">
                    <app-sub-header [fromPage]="strPayment"></app-sub-header>
                    <!-- <div class="policy-header d-flex">
                        <div class="pol-header-l">
                            <h1 class="main-heading">Policy Information</h1>
                            <span class="login-time">Last Login Sunday January 26th 2020. 04:47PM EST</span>
                        </div>
                        <div class="pol-header-r fs-20">
                            <span class="pol-detl"><strong>Policy No:</strong> {{policyNumber}}</span>
                            <span class="pol-detl"><strong>Policy Period:</strong> {{effectiveDate}} - {{expirationDate}}</span>
                        </div>
                    </div> -->
                    <div class="edit-card-cover d-flex">
                        <div class="col-md-4 col-12 pl-0" *ngIf="isAdded == false">
                            <div class="payment-det-innet edit-card-tab">
                                <div class="payment-card-des">
                                    <div class="card-des-inner">
                                        <div class="visa-logo"><img src="../../../../assets/images/visa-icon.svg"></div>
                                        <div class="card-number fs-20"><span>XXXX</span> <span>XXXX</span> <span>XXXX</span> <span>5432</span></div>
                                        <div class="card-small-numb">1234</div>
                                        <div class="card-identity">
                                            <div class="c-name">James Born</div>
                                            <div class="exp-dt">
                                                <span class="valid-thru">Valid<br>Thru</span>
                                                <span class="dt">03/17</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="amount-charge">
                                    <strong>Next Amount to be Charged</strong>
                                    <div class="amt-count">${{installmentAmount}}</div>
                                    <div class="pay-btn gradient-btn"><button type="button" (click)="payNowRedirection()">Pay Now</button></div>
                                    <div class="select-payment-meth"><a href="#">Setup recurring payments</a></div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-8 col-12 pr-0">
                            <div class="edit-card-info">
                                <div class="col-12">
                                    <h3 class="card-holder-name fs-20">CardHolders Name</h3>
                                     <span class="card-inp-field">
                                    <input [(ngModel)]="ccDetailForm.ccName" type="text" name="" style="width:300px">
                                    </span>
                                    <span class="fs-20 card-type">CARD TYPE</span>
                                </div>
                                <div class="e-card-number">
                                    <label>Card Number</label>
                                    <span class="card-inp-field">
										<input mask="0000-0000-0000-0000" [(ngModel)]="ccDetailForm.ccNumber" type="text" name="" style="width:500px;">
										<!-- <input type="text" name="">
										<input type="text" name="">
										<input type="text" name=""> -->
									</span>
                                </div>
                                <div class="col-12 d-flex">
                                    <div class="card-expr-dt">
                                        <label>Expiration Date</label>
                                        <span class="card-inp-field">
											<!-- <input [(ngModel)]="ccDetailForm.ccExpMonth" type="month" name=""> -->
                                            <input mask="00" [(ngModel)]="ccDetailForm.ccExpMonth" type="text" name="">
                                            <span class="field-devider">/</span>
                                            <input mask="0000" [(ngModel)]="ccDetailForm.ccExpYear" type="year" name="" style="width:75px;">
                                        </span>
                                    </div>
                                    <div class="cvc-num">
                                        <label>CVV</label>
                                        <span class="card-inp-field">
											<input mask="0000" [(ngModel)]="ccDetailForm.ccCVV" type="text" name="">
										</span>
                                    </div>
                                    <div class="cvc-num">
                                        <label>ZIPCODE</label>
                                        <span class="card-inp-field">
											<input mask="000000" [(ngModel)]="ccDetailForm.ccZipCode" type="text" name="">
										</span>
                                    </div>
                                </div>
                            </div>
                            <div class="add-card-btn gradient-btn">
                                <input type="checkbox" style="margin-right:10px;" [(ngModel)]="ccDetailForm.isDefault">
                                <span style="margin-right:100px; font-size:16px;">
                                <strong>Set this card as default</strong>
                                </span>
                                
                                <button type="button" (click)="addCCDetails()">Add Card</button>
                                <br/><br/>
                                <div>
                                    <span style="color:red;"><strong>{{message}}</strong></span>
                                </div>   
                            </div>
                        
                        </div>
                         
                    </div>
                </div>
            </div>
        </div>
    </section>
    <app-footer></app-footer>
</body>