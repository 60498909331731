<header>
    <div class="container">
        <div class="header-inner d-flex row">
            <div class="header-logo">
                <a href="#"><img src="../../../../assets/images/excepsure-logo.png"></a>
            </div>
            <nav class="navbar navbar-expand-md tabing_navigatiin navbar-dark">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                    <span class="navbar-toggler-icon"></span>
                 </button>
                <div class="collapse navbar-collapse" id="collapsibleNavbar">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a class="nav-link" href="#">Policy Details</a></li>
                        <li class="nav-item active"><a class="nav-link" href="#">Payments</a></li>
                        <li class="nav-item"><a class="nav-link" href="#">Policy Documents</a></li>
                    </ul>
                </div>
            </nav>

            <div class="right_header d-flex">
                <div class="my-account">
                    <div class="dropdown">
                        <div class="dropdown-toggle" id="menu1" data-toggle="dropdown">
                            <span class="user-icon">
                                <img src="../../../../assets/images/MyAccount.svg">
                            </span>
                            <span class="mobile_rob">Rob Waters</span>
                        </div>
                        <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">

                            <li><a href="#"><span class="dw-icon"><img src="../../../../assets/images/settings.svg"></span> Settings</a></li>
                            <li><a href="#"><span class="dw-icon"><img src="../../../../assets/images/logout.svg"></span> Sign Out</a></li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>