import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/observable';
import { of } from 'rxjs/observable/of';
import {ErrorObservable} from 'rxjs/observable/ErrorObservable';
import {catchError} from 'rxjs/operators';
import {AppConstants} from '../utilities/app.constant';
import { Injectable } from '@angular/core';
import {ICustomerDetailModel} from '../../core/interfaces/app.interface';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
  })
  export class CustomerService{

    constructor(
        private http: HttpClient,
    ){}

    postCustomerDetail(customerDetailModel:ICustomerDetailModel): Observable<any>{
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json'
            })
          };
          return this.http.post(environment.urls.apiBaseURL + 'v1/customer-detail', customerDetailModel, httpOptions)
          .pipe(catchError(this.handleError));
    }

    sendForgotPasswordLink(policyNumber:string): Observable<any>{
      const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        };
        return this.http.get(environment.urls.apiBaseURL + 'v1/forgotpassword/' +policyNumber, httpOptions)
        .pipe(catchError(this.handleError));
    }

    verifyForgotPassword(policyNumber:string,verificationCode:string): Observable<any>{
      const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        };
        return this.http.get(environment.urls.apiBaseURL + 'v1/verifyforgotpassword?policyNumber=' +policyNumber+'&verificationCode='+verificationCode, httpOptions)
        .pipe(catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status == 401) {
            return new ErrorObservable(error.error.message);
        }
        console.log('Status:' + error.status + ',' + error.error.message);
        return new ErrorObservable(error.error.message);
    };
  }
