import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CoreComponent} from './core.component';

@NgModule({
    declarations: [
		CoreComponent
	],
	imports: [
	
	
	],
	providers: []
})
export class CoreModule {}