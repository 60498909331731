import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AlertService } from '../../../core/services/alert.service';
import { PolicyService } from '../../../core/services/policy.service';
import { LoginService } from '../../../core/services/login.service';
import { DatePipe } from '@angular/common';
import { IPolicyDetails, IAutoVehicles, IAutoDrivers, IAgencyInfo } from '../../../core/interfaces/app.interface';
import { PaymentService } from 'src/app/core/services/payment.service';
import { isNullOrUndefined } from 'util';
import { PartialService } from '../../partials/partial.service';
import { EPage } from 'src/app/core/enums/app.enums';
import Swal from 'sweetalert2';
// Declare loader function
declare function loadingServiceShow(zindex, id, flag);
declare function loadingServiceHide(id);

@Component({
  selector: 'app-policy-details',
  templateUrl: './policy-details.component.html',
  styleUrls: ['./policy-details.component.css']
})
export class PolicyDetailsComponent implements OnInit {
  policyDetails: IPolicyDetails = <IPolicyDetails>{};
  autoVehicles: IAutoVehicles[] = [];
  autoDrivers: IAutoDrivers[] = [];
  agencyInfo:IAgencyInfo;
  policyNumber: string;
  policyStatus: string;
  effectiveDate: string;
  isCancelPolicy:boolean = false;
  lastPaymentDate: String;
  expirationDate: string;
  paymentDate: string;
  firstName: string;
  lastName: string;
  day: string;
  lastLoginDate: string;
  isRecurring: boolean;
  isPaymentAllowed: boolean;
  mySubscription: any;
  isShow: boolean = false;
  strPayment: string = EPage[EPage.PolicyDetail].toString();
  isVehicleExist:boolean = false;
  isAgencyInfoExist:boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private policyService: PolicyService,
    private loginService: LoginService,
    public datepipe: DatePipe,
    public paymentService: PaymentService,
    private alertservice: AlertService,
    private partialService: PartialService
  ) { }

  ngOnInit() {
    loadingServiceShow(1, 'dvPolicyDetail', true);

    this.policyNumber = this.route.snapshot.params['policynumber'];
    if (this.policyNumber != null) {
      this.getPolicyNumberByDetails(this.policyNumber);
    }
    this.firstName = localStorage.getItem('firstname');
    this.lastName = localStorage.getItem('lastname');
    this.lastLoginDate = localStorage.getItem('lastLoginDate');
  }

  getPolicyNumberByDetails(policyNumber: string) {
    this.policyService.PolicyDetailsByPolicyNumber(policyNumber).subscribe(data => {
      loadingServiceHide('dvPolicyDetail');
      if(data.status==401)
        {
          Swal.fire('', 'Authentication Failed', 'warning')
          return false
        }
      if (data.data != null) 
      {
        if (data.status == 200) {
          this.policyDetails = data.data;
          if (!isNullOrUndefined(sessionStorage.getItem('dueAmount')) && Number(sessionStorage.getItem('dueAmount')) > 0) {
            this.policyDetails.amount = Number(sessionStorage.getItem('dueAmount'));
          }
          var effectiveDate = this.datepipe.transform(data.data["effectiveDate"], 'M/yy');
          data.data["effectiveDate"] = effectiveDate;
          this.effectiveDate = effectiveDate;
          var expirationDate = this.datepipe.transform(data.data["expirationDate"], 'M/yy');
          data.data["expirationDate"] = expirationDate;
          this.expirationDate = expirationDate;
          var paymentDate = this.datepipe.transform(data.data["installmentDueDate"], 'M/d/yy');
          data.data["installmentDueDate"] = paymentDate;
          this.paymentDate = paymentDate;
          var day = this.datepipe.transform(data.data["installmentDueDate"], 'EEEE');
          data.data["installmentDueDate"] = day;
          this.day = day;
          var lastDate = this.datepipe.transform(data.data["lastPaymentDate"], 'M/d/yy');
          data.data["lastPaymentDate"] = lastDate;
          this.lastPaymentDate = lastDate;
          this.autoDrivers = data.data.autoDrivers;
          this.autoVehicles = data.data.autoVehicles;
          debugger;
          if(data.data.agencyInfo!="" && data.data.agencyInfo!=undefined && data.data.agencyInfo!=null)
          {
            this.agencyInfo = data.data.agencyInfo;
            this.isAgencyInfoExist = true;
          }
          if(data.data.autoVehicles!=null && data.data.autoVehicles!=undefined && data.data.autoVehicles.length > 0)
          {
            this.isVehicleExist = true;
          }
          
          if (!isNullOrUndefined(sessionStorage.getItem('dueAmount')) && Number(sessionStorage.getItem('dueAmount')) > 0) {
            let amount = sessionStorage.getItem('dueAmount');
            localStorage.setItem('installmentAmount', amount);
          }
          else {
            localStorage.setItem('installmentAmount', this.policyDetails.amount.toString());
          }
          
          if(data!= null && data.data != null && !isNullOrUndefined(data.data.cancelDate) && data.data.cancelDate != '' &&  (data.data.status.toLowerCase().includes("cancelled") || data.data.status.toLowerCase().includes("notice")))
          {
            let cancelDate = this.datepipe.transform(data.data["cancelDate"], 'MM/dd/yyyy');
            localStorage.setItem("policyCancelDate",cancelDate);
            this.policyDetails.cancelDate = cancelDate;
            this.partialService.cancelDate = cancelDate;
          }
          else
          {
            this.partialService.cancelDate = ""; 
          }
          if (data != null && data.data != null && !isNullOrUndefined(data.data.status) && data.data.status != '') {
            localStorage.setItem("policyStatus", data.data.status);
            this.policyStatus = data.data.status;
          }
          this.partialService.policyStatus = data.data.status;
          if(data.data.status.toLowerCase().includes("cancelled"))
          {
            this.partialService.isCancel = true;
            this.isCancelPolicy = true;
          }
          else if(data.data.status.toLowerCase().includes("active"))
          {
            this.partialService.isActive = true;
          }
          else if(data.data.status.toLowerCase().includes("notice"))
          {
            this.partialService.isNotice = true;
          }
          localStorage.setItem('isPostable', data.data.isPaymentAllowed); 
          localStorage.setItem('garageAddress1', data.data.garageAddress1);
          localStorage.setItem('garageAddress2', data.data.garageAddress2);
          localStorage.setItem('garageCity', data.data.garageCity);
          localStorage.setItem('garageState', data.data.garageState);
          localStorage.setItem('garageZip', data.data.garageZip);
          localStorage.setItem('isRecurring', data.data.isRecurring);
          localStorage.setItem('paymentDueDate', this.policyDetails.paymentDueDate.toString());
          this.isRecurring = data.data.isRecurring;
          debugger;
          this.isPaymentAllowed = data.data.isPaymentAllowed;
          loadingServiceHide('dvPolicyDetail');
          this.router.navigate['/']

        }
      }
      else{
        localStorage.clear();
        sessionStorage.clear();
      this.router.navigate(["/login"]);
      }
    }, error => {
      console.log();
    })
  }

  doLogout() {
    this.loginService.logOut().subscribe(data => {
      this.partialService.ClearPartialValues();
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(["/login"]);
    }, error => {
      console.log();
    });
  }

  policyDetail() {
    this.router.navigate(['/policy-details/' + this.policyNumber]);
    return false;
  }

  payment() {
    this.router.navigate(['/payment-history/' + this.policyNumber])
    return false;
  }

  policyDocuments() {
    this.router.navigate(['/policy-documents/' + this.policyNumber])
    return false;
  }

  payNowRedirection() {
    this.router.navigate(["/payment-card-details"]);
    return false;
  }

}


