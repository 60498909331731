import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { IPolicyDetails, IDocumentUrlModel } from '../../core/interfaces/app.interface';
import { Observable } from 'rxjs/observable';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { of } from 'rxjs/observable/of'; // for static methods
import { catchError } from 'rxjs/operators';
import { AppConstants } from '../utilities/app.constant';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class PolicyService {
  constructor(
    private http: HttpClient,
  ) { }

  PolicyDetailsByPolicyNumber(policyNumber: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'AuthToken': localStorage.getItem('AuthToken')
      })
    };
    return this.http.get(environment.urls.apiBaseURL + 'v1/policy-details/' + policyNumber, httpOptions)
      .pipe(catchError(this.handleError));
  }



  RecurringPolicyDetailsByToken(token: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(environment.urls.apiBaseURL + 'v1/recurring-policy-details/' + token, httpOptions)
      .pipe(catchError(this.handleError));
  }


  PolicyDocumentByPolicyNumber(policyNumber: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'AuthToken': localStorage.getItem('AuthToken')
      })
    };
    return this.http.get(environment.urls.apiBaseURL + 'v1/policy-documents/' + policyNumber, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetQuoteByPolicy(policyNumber:string):Observable<any>{
    const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'AuthToken': localStorage.getItem('AuthToken')
        })
      };
      return this.http.get(environment.urls.apiBaseURL + 'get-quote-by-pno/' + policyNumber,httpOptions)
        .pipe(catchError(this.handleError));
}


getPolicyPaperWorkFile(quoteId:string,policyCd:string): Observable<any>{
  const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'AuthToken': localStorage.getItem('AuthToken')

      })
    };
    return this.http.get(environment.urls.apiBaseURL + "a/paperwork/" + quoteId + "/" + policyCd,  httpOptions)
    .pipe(catchError(this.handleError));
}


  private handleError(error: HttpErrorResponse) {
    if (error.status == 401) {
      return new ErrorObservable(error.error.message);
    }
    console.log('Status:' + error.status + ',' + error.error.message);
    return new ErrorObservable(error.error.message);
  };
}