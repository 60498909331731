import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../core/services/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PaymentService } from '../../../core/services/payment.service';
import { PaymentHistoryModel, PaymentScheduleModel, IPolicyDetails } from '../../../core/interfaces/app.interface';
import { isNullOrUndefined } from 'util';
import { EventManager } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { PolicyService } from 'src/app/core/services/policy.service';
import { EPage } from 'src/app/core/enums/app.enums';
import { PartialService } from '../../partials/partial.service';
import Swal from 'sweetalert2';
// Declare loader function
declare function loadingServiceShow(zindex, id, flag);
declare function loadingServiceHide(id);

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.css']
})
export class PaymentHistoryComponent implements OnInit {
  firstName: string;
  lastName: string;
  policyNumber: string;
  effectiveDate: string;
  expirationDate: string;
  lastLoginDate: string;
  installmentAmount: string;
  garageAddress1: string;
  garageAddress2: string;
  garageCity: string;
  garageState: string;
  garageZip: string;
  paymentHistoryList: PaymentHistoryModel[] = [];
  paymentScheduleList: PaymentScheduleModel[] = [];
  policyDetailsInfo: IPolicyDetails = <IPolicyDetails>{};
  isValidAmount:boolean = true;
  isPaymentAllowed:boolean;
  dueAmount:number = 0;
  dueDate:string;
  isFullPaid:string;
  dueDateText:string;
  strPayment: string = EPage[EPage.Payment].toString();
  cardHolderName:string;
  cardNumber:string;
  constructor(
    private loginService: LoginService,
    private policyService:PolicyService,
    private partialService:PartialService,
    private router: Router,
    private route: ActivatedRoute,
    private PaymentService: PaymentService,
    public datepipe: DatePipe
  ) { }

  ngOnInit() {
    loadingServiceShow(1, 'dvPaymentHistory', true);
    this.isValidAmount = true;
    this.firstName = localStorage.getItem('firstname');
    this.lastName = localStorage.getItem('lastname');
    this.policyNumber = localStorage.getItem('policynumber');
    this.effectiveDate = localStorage.getItem('effectiveDate');
    this.expirationDate = localStorage.getItem('expirationDate');
    this.lastLoginDate = localStorage.getItem('lastLoginDate');
    this.installmentAmount = localStorage.getItem('installmentAmount');

    this.policyNumber = this.route.snapshot.params['policynumber'];
    if(this.policyNumber != null){
      this.getPolicyNumberByDetails(this.policyNumber);
    }

    if(isNullOrUndefined(this.installmentAmount)) // Set Initial Amount If Installment amount missing
    {
      this.installmentAmount = parseFloat("0").toFixed(2).toString();
    }
    if(!isNullOrUndefined(localStorage.getItem('paymentDueDate')))
    {
      this.dueDate = localStorage.getItem('paymentDueDate').toString();
    }
    
    if (!isNullOrUndefined(sessionStorage.getItem('dueAmount'))) {
      this.installmentAmount = sessionStorage.getItem('dueAmount');
    }
    this.garageAddress1 = localStorage.getItem('garageAddress1');
    this.garageAddress2 = localStorage.getItem('garageAddress2');
    this.garageCity = localStorage.getItem('garageCity');
    this.garageState = localStorage.getItem('garageState');
    this.garageZip = localStorage.getItem('garageZip');
    this.getPaymentHistoryDetails();
    
  }


  doLogout() {
    this.loginService.logOut().subscribe(data => {
      this.partialService.ClearPartialValues();
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(["/login"]);
    }, error => {
      console.log();
    });
  }

  
  getPaymentHistoryDetails() {
    let paymentDueDate =localStorage.getItem("paymentDueDate");
    this.PaymentService.GetPaymentHistory(this.policyNumber,paymentDueDate).subscribe(data => {
      console.log(data)
      loadingServiceHide('dvPaymentHistory');
      if(data.status==401)
      {
        Swal.fire('', 'Authentication Failed', 'warning')
        return false
      }
      if (data.status == 200) 
      {
        if (data.data != null) 
        {
          this.paymentHistoryList = data.data.quotePayments;
          if(isNullOrUndefined(data) || isNullOrUndefined(data.data) || isNullOrUndefined(data.data.defaultCardHolderName))
          {
              this.cardNumber = "XXXX";
          }
          else if(!isNullOrUndefined(data.data.defaultCardHolderName) && !isNullOrUndefined(data.data.defaultCardNumber))
          {
            this.cardHolderName = data.data.defaultCardHolderName;
            this.cardNumber = data.data.defaultCardNumber;
          }
          this.paymentScheduleList = data.data.paymentSchedule;
          if(!isNullOrUndefined(data.data.paymentSchedule) && data.data.paymentSchedule.length > 0)
          {
           var lastPaidStatus = data.data.paymentSchedule[data.data.paymentSchedule.length-1].paidOrNot; // get last paid status 
           if(!isNullOrUndefined(lastPaidStatus)) 
           {
              this.isValidAmount = lastPaidStatus;
           }
          }
          loadingServiceHide('dvPaymentHistory');
        }
      }
    })
  }

  
  getPolicyNumberByDetails(policyNumber:string){
    debugger
    this.policyService.PolicyDetailsByPolicyNumber(policyNumber).subscribe(data=>{
      loadingServiceHide('dvPolicyDetail');
      
      if(data.status==401)
      {
        Swal.fire('', 'Authentication Failed', 'warning')
        return false
      }
      if(data.data != null){
        if(data.status == 200)
        {
    debugger

           this.policyDetailsInfo=data.data;
           if(!isNullOrUndefined(sessionStorage.getItem('dueAmount')) && Number(sessionStorage.getItem('dueAmount')) > 0)
           {
             let amount = sessionStorage.getItem('dueAmount');
             localStorage.setItem('installmentAmount',amount);
           }
           else
           {
             localStorage.setItem('installmentAmount',this.policyDetailsInfo.amount.toString());   
           }
           if(!isNullOrUndefined(this.policyDetailsInfo) && this.policyDetailsInfo.amount > 0 &&  (isNullOrUndefined(sessionStorage.getItem('dueAmount')) || Number(sessionStorage.getItem('dueAmount')) == 0))
           {
            this.installmentAmount = this.policyDetailsInfo.amount.toString();
           }

           this.partialService.policyStatus = data.data.status;
           
          if(data!=null && data.data!=null && !isNullOrUndefined(data.data.status) && data.data.status!='')
          { 
            localStorage.setItem("policyStatus",data.data.status);
           
            if(data.data.status.toLowerCase().includes("cancelled"))
            {
              this.partialService.isCancel = true;
            }
            else if(data.data.status.toLowerCase().includes("active"))
            {
              this.partialService.isActive = true;
            }
            else if(data.data.status.toLowerCase().includes("notice"))
            {
              this.partialService.isNotice = true;
            }
          }
          

           localStorage.setItem('isPostable',data.data.isPaymentAllowed);
           localStorage.setItem('garageAddress1',data.data.garageAddress1);
           localStorage.setItem('garageAddress2',data.data.garageAddress2);
           localStorage.setItem('garageCity',data.data.garageCity);
           localStorage.setItem('garageState',data.data.garageState);
           localStorage.setItem('garageZip',data.data.garageZip);
           localStorage.setItem('isRecurring',data.data.isRecurring);
           localStorage.setItem('paymentDueDate',this.policyDetailsInfo.paymentDueDate.toString());
           if(!isNullOrUndefined(this.policyDetailsInfo.paymentDueDate))
           {
            this.dueDate = this.policyDetailsInfo.paymentDueDate.toString();
           }
           this.isFullPaid = data.data.isFullPay;
           loadingServiceHide('dvPolicyDetail');
           this.isPaymentAllowed = data.data.isPaymentAllowed;
         }
      }
      else{
        localStorage.clear();
        sessionStorage.clear();
      this.router.navigate(["/login"]);
      }
    },error=>{
      console.log();
    })
   }

  policyDetails() {
    this.router.navigate(['/policy-details/' + this.policyNumber]);
    return false;
  }

  payment() {
    this.router.navigate(['/payment-history/' + this.policyNumber])
    return false;
  }

  policyDocuments() {
    this.router.navigate(['/policy-documents/' + this.policyNumber])
    return false;
  }

  addPaymentCC() {
    this.router.navigate(['/payment-card-details'])
    return false;
  }

  payNowRedirection() {
    this.router.navigate(["/payment-card-details"]);
    return false;
  }
}

