<body>
    <header>
        <div class="container" data-loader="dvPaymentHistory">
            <div class="header-inner d-flex row">
                <div class="header-logo">
                    <a href="#"><img src="../../../../assets/images/excepsure-logo.png"></a>
                </div>
                <nav class="navbar navbar-expand-md tabing_navigatiin navbar-dark">
                    <button class="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#collapsibleNavbar">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="collapsibleNavbar">
                        <ul class="navbar-nav">
                            <li class="nav-item"><a class="nav-link pointer" (click)="policyDetails()">Policy
                                    Details</a></li>
                            <li class="nav-item active"><a class="nav-link  pointer" (click)="payment()">Payments</a>
                            </li>
                            <li class="nav-item"><a class="nav-link  pointer" (click)="policyDocuments()">Policy
                                    Documents</a></li>
                        </ul>
                    </div>
                </nav>

                <div class="right_header d-flex">
                    <div class="my-account">
                        <div class="dropdown">
                            <div class="dropdown-toggle" id="menu1" data-toggle="dropdown"><span class="user-icon"><img
                                        src="../../../../assets/images/MyAccount.svg"></span> <span
                                    class="mobile_rob">{{firstName}} {{lastName}}</span></div>
                            <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                                <!-- <li><a href="#"><span class="dw-icon"><img src="../../../../assets/images/settings.svg"></span> Settings</a></li> -->
                                <li><a (click)="doLogout()"><span class="dw-icon"><img
                                                src="../../../../assets/images/logout.svg"></span> Sign Out</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section>
        <div class="container pd-container">
            <div class="row">
                <div class="col-12 policy-container">
                    <app-sub-header [fromPage]="strPayment"></app-sub-header>
                    <div class="payment-sum-panel d-flex">
                        <div class="col-md-8 col-12 pl-0">
                            <div class="payment-det-innet">
                                <div class="payment-card-des">
                                    <div class="card-des-inner">
                                        <div class="visa-logo"><img src="../../../../assets/images/visa-icon.svg"></div>
                                        <div class="card-number fs-20"><span>XXXX</span> <span>XXXX</span>
                                            <span>XXXX</span> <span>{{cardNumber}}</span></div>
                                        <div class="card-small-numb">XXXX</div>
                                        <div class="card-identity">
                                            <div class="c-name">{{cardHolderName}}</div>
                                            <div class="exp-dt">
                                                <span class="valid-thru">Valid<br>Thru</span>
                                                <span class="dt">XX/XX</span>
                                            </div>
                                        </div>
                                        <!-- <div class="card-edit">
                                            <a (click)="addPaymentCC()" class="pointer"><img src="../../../../assets/images/edit-icon.svg"></a>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="amount-charge">
                                    <div class="row ml-35">
                                        <h5>Payment Due:</h5>
                                        <h4 class="ml-10">{{ dueDate | date:'MM/dd/yyyy' }}</h4>
                                    </div>

                                    <div class="amt-count">
                                        ${{ installmentAmount | number : '1.2-2' }}
                                    </div>
                                    <div><b>*Amount due includes applicable fees</b></div>
                                    <div class="pay-btn gradient-btn" *ngIf="policyDetailsInfo!=null &&  policyDetailsInfo.amount > 0"><button type="button"
                                            (click)="payNowRedirection()">Make Payment</button></div>
                                    <!-- <div class="select-payment-meth pointer" *ngIf="isPaymentAllowed"><a class="fs-xlarge" (click)="addPaymentCC()">Setup recurring payments</a></div> -->
                                </div>
                            </div>
                            <div class="panel-box payment-histoey">
                                <div class="box-strip">PAYMENT HISTORY</div>
                                <div class="box-cont">
                                    <div class="panel-bordered-table">
                                        <table>
                                            <!-- <div *ngFor="let payment of paymentHistoryList"> -->
                                            <tr *ngFor="let payment of paymentHistoryList">
                                                <td>{{payment.paymentDate | date:'MM/dd/yyyy'}}</td>
                                                <td>{{payment.paymentMethodName}}</td>
                                                <td>${{payment.paidAmount | number : '1.2-2'}}</td>
                                                <!--<td>{{payment.paymentStatusName}}</td>-->
                                            </tr>
                                            <!-- </div> -->
                                            <!-- <tr>
                                                <td>12/30/2019</td>
                                                <td>Credit Card</td>
                                                <td>$535.59</td>
                                                <td>APPROVED</td>
                                            </tr>
                                            <tr>
                                                <td>11/30/2019</td>
                                                <td>Credit Card</td>
                                                <td>$535.59</td>
                                                <td>APPROVED</td>
                                            </tr>
                                            <tr>
                                                <td>10/30/2019</td>
                                                <td>Credit Card</td>
                                                <td>$535.59</td>
                                                <td>APPROVED</td>
                                            </tr>
                                            <tr>
                                                <td>09/30/2019</td>
                                                <td>Credit Card</td>
                                                <td>$535.59</td>
                                                <td>RENEWAL</td>
                                            </tr> -->
                                        </table>
                                        <!-- <h3 class="panel-sub-title">PREVIOUS TERM</h3>
                                        <table>
                                            <tr>
                                                <td>08/30/2019</td>
                                                <td>Credit Card</td>
                                                <td>$535.59</td>
                                                <td>APPROVED</td>
                                            </tr>
                                            <tr>
                                                <td>08/27/2019</td>
                                                <td>Credit Card</td>
                                                <td>$535.59</td>
                                                <td class="text-red">FAILED</td>
                                            </tr>
                                            <tr>
                                                <td>07/27/2019</td>
                                                <td>Credit Card</td>
                                                <td>$535.59</td>
                                                <td>APPROVED</td>
                                            </tr>
                                            <tr>
                                                <td>06/27/2019</td>
                                                <td>Credit Card</td>
                                                <td>$535.59</td>
                                                <td>RENEWAL</td>
                                            </tr>
                                            <tr>
                                                <td>05/27/2019</td>
                                                <td>Credit Card</td>
                                                <td>$535.59</td>
                                                <td>RENEWAL</td>
                                            </tr>
                                            <tr>
                                                <td>04/27/2019</td>
                                                <td>Credit Card</td>
                                                <td>$601.0.1</td>
                                                <td>NEW ACC</td>
                                            </tr>
                                        </table> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-12 pl-2 pr-0">
                            <div class="panel-box">
                                <div class="box-strip">NAMED INSURED</div> <!--  <span class="strip-right-text"><a
                                    href="#">Edit</a></span>-->
                                <div class="box-cont">
                                    <div class="insured-box">
                                        <h2 class="main-heading">{{firstName}} {{lastName}}</h2>
                                        <span class="address fs-20"><strong>{{garageAddress1}}<br>
                                                {{garageCity}} {{garageState}} {{garageZip}}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-box payment-schd" *ngIf="!isFullPaid">
                                <div class="box-strip">PAYMENT SCHEDULE</div>
                                <div class="box-cont py-3">
                                    <div class="pay-schd-table">
                                        <table>
                                            <tr *ngFor="let payment of paymentScheduleList">
                                                <td colspan="2" style="width:35%">{{payment.paymentNo}}</td>
                                                <td>${{payment.paymentAmount | number : '1.2-2'}}</td>
                                                <td>{{payment.dueDate  | date:'MM/dd/yyyy'}}</td>
                                                <td class="text-green payment-status"
                                                    *ngIf="payment.paidOrNot==true && payment.paymentStatus!=''">PAID
                                                </td>
                                                <td class="text-green payment-status"
                                                    *ngIf="payment.paidOrNot==false && payment.paymentStatus!=''"
                                                    style="color:red !important"  tooltip="amount due includes all applicable fees." placement="right">
                                                    DUE
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-contact-details></app-contact-details>
        </div>
    </section>
    <app-footer></app-footer>
</body>