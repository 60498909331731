import { Component, OnInit } from '@angular/core';
import { AbstractControl,FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {LoginService} from '../../../core/services/login.service';
import { ILoginModel, IPolicyDetails, ISignUp } from 'src/app/core/interfaces/app.interface';
import {AlertService} from '../../../core/services/alert.service';
import { DatePipe } from '@angular/common';
import {md5} from '../../../core/utilities/md5';
import { isNullOrUndefined } from 'util';
import { PolicyService } from 'src/app/core/services/policy.service';
import { PartialService } from '../../partials/partial.service';

// Declare loader function
declare function loadingServiceShow(zindex, id, flag);
declare function loadingServiceHide(id);

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  login:FormGroup;
  signUp:FormGroup;
  islogin: boolean = false;
  isSignUp: boolean = false;
  policyNumber:string;
  showModal: boolean;
  content: string;
  title: string;
  loginDetails:ILoginModel={policyNumber:null,password:null,email:null,loginPassword:null}
  signUpDetails:ISignUp={policyNumber:null,zipCode:null,birthDate:null}
  policyDetails: IPolicyDetails = <IPolicyDetails>{};
  
  

  constructor(
    private formBuilder:FormBuilder,
    private router: Router,
    private loginservice: LoginService,
    private policyService: PolicyService,
    private alertservice:AlertService,
    public datepipe: DatePipe,
    public partialService:PartialService

  ) { }

  ngOnInit() {
    this.login=this.formBuilder.group({
      loginpolicynumber: [null, [Validators.required]],
      loginPassword: [null, [Validators.required]],
    });

    this.signUp=this.formBuilder.group({
      txtpolicynumber: [null, [Validators.required]],
      dob: [null, Validators.required],
    });
    
  }

  get loginpolicynumber() { return this.login.get('loginpolicynumber'); }
  get loginPassword() { return this.login.get('loginPassword'); }
  get txtpolicynumber() { return this.signUp.get('txtpolicynumber'); }
  get dob() { return this.signUp.get('dob'); }

  loginSubmit()
  {
    debugger;
    if(this.login.valid)
    {
    debugger;
      loadingServiceShow(1, 'dvLogin', true);
      let password = md5(this.loginDetails.loginPassword);
      this.loginDetails.password=password;
    this.loginservice.login(this.loginDetails).subscribe(data=>{
      loadingServiceHide('dvLogin');
      if(data.data != null)
      {
        if (data.status = 200)
        {
          debugger;
          var effectiveDate = this.datepipe.transform(data.data.effectiveDate, 'M/yy');
          data.data.effectiveDate = effectiveDate;
          var expirationDate = this.datepipe.transform(data.data.expirationDate, 'M/yy');
          data.data.expirationDate = expirationDate;
          localStorage.setItem('policynumber', data.data.policyNumber);
          localStorage.setItem('firstname',data.data.firstName);
          localStorage.setItem('lastname',data.data.lastName);
          localStorage.setItem('effectiveDate',effectiveDate);
          localStorage.setItem('expirationDate',expirationDate);
          localStorage.setItem('producer',data.data.producer);
          localStorage.setItem('recurring',data.data.isrecurring);
          localStorage.setItem('AuthToken',data.data.token);
          if(data.data.lastLoginDate == null){
            data.data.lastLoginDate = this.datepipe.transform(new Date(),'MM/dd/yyyy hh:mm:ss');
          }
          localStorage.setItem('lastLoginDate',data.data.lastLoginDate);
          this.policyNumber=data.data.policyNumber;
          // if(this.policyNumber!="" && this.policyNumber!=undefined)
          // {
          //   this.getPolicyNumberByDetails(this.policyNumber);
          // }
          //this.router.navigate(["/policy-documents/" + this.policyNumber]);
          if(this.policyNumber!="" && this.policyNumber!=undefined)
          {
           this.router.navigate(["/policy-details/" + this.policyNumber]);
          }
          else{
            loadingServiceHide('dvLogin');
            this.alertservice.warning("Please try again!");
          }
        }
      }
    },error =>{
      loadingServiceHide('dvLogin');
      this.alertservice.warning("Invalid user details !");
    })
    }  else{
      this.alertservice.warning('Please fill all required details');
      return false;
    }
  }


  getPolicyNumberByDetails(policyNumber: string) {
    this.policyService.PolicyDetailsByPolicyNumber(policyNumber).subscribe(data => {
      if (data.data != null) {
        if (data.status = 200) 
        {
            this.policyDetails=data.data;
            if (data != null && data.data != null && !isNullOrUndefined(data.data.status) && data.data.status != '') 
            {
              this.partialService.policyStatus = data.data.status;
              if(data.data.status.toLowerCase().includes("cancelled"))
              {
                this.partialService.isCancel = true;
              }
              else if(data.data.status.toLowerCase().includes("active"))
              {
                this.partialService.isActive = true;
              }
              else if(data.data.status.toLowerCase().includes("notice"))
              {
                this.partialService.isNotice = true;
              }
              localStorage.setItem("policyStatus", data.data.status);
            }
        }
        else{
          localStorage.clear();
          sessionStorage.clear();
        this.router.navigate(["/login"]);
        }
      }
    }, error => {
      console.log();
    })
  }
  
  show()
  {
    this.showModal = true;
  }

  hide()
  {
    window.location.reload();
  }

  checkRenewal(){
    debugger;
    if(this.signUp.valid){
      this.loginservice.checkRenewal(this.signUpDetails).subscribe(data=>{
        console.log(data);
        if(data.data != null){
    debugger;

          if(data.data.status == 200 && data.data.message == null){
            //  this.alertservice.warning('Please contact your agent to renew you policy.');
            
            //QA Redirection
            // window.location.href = "http://arrowhead-pos-customer-dev.azurewebsites.net/arrowhead/renewal?policy=" + this.signUpDetails.policyNumber;

            //Production Redirection
             window.location.href = "https://agentdirect.arrowheadauto.com/arrowhead/renewal?policy=" + this.signUpDetails.policyNumber;

          } else if(data.data.message != null && data.data.status == null){
            this.alertservice.warning(data.data.message);
            //  this.alertservice.warning('Please contact your agent to renew you policy.');
          } else{
            this.alertservice.warning('Please contact your agent to renew you policy.');
          }
        }else{
          this.alertservice.warning('Please contact your agent to renew you policy.');
        }  
      })
    }else{
      this.alertservice.warning('Please fill all required details');
      return false;
    }
  }
  
}
