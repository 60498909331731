<body data-loader="dvPaymentCardDetail">
    <header>
        <div class="container">
            <div class="header-inner d-flex row">
                <div class="header-logo">
                    <a href="#"><img src="../../../../assets/images/excepsure-logo.png"></a>
                </div>
                <nav class="navbar navbar-expand-md tabing_navigatiin navbar-dark">
                    <button class="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#collapsibleNavbar">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="collapsibleNavbar">
                        <ul class="navbar-nav">
                            <li class="nav-item"><a class="nav-link" (click)="policyDetails()">Policy Details</a></li>
                            <li class="nav-item active"><a class="nav-link" (click)="payment()">Payments</a></li>
                            <li class="nav-item"><a class="nav-link" (click)="policyDocuments()">Policy Documents</a>
                            </li>
                        </ul>
                    </div>
                </nav>

                <div class="right_header d-flex">
                    <div class="my-account">
                        <div class="dropdown">
                            <div class="dropdown-toggle" id="menu1" data-toggle="dropdown"><span class="user-icon"><img
                                        src="../../../../assets/images/MyAccount.svg"></span> <span
                                    class="mobile_rob">{{firstName}} {{lastName}}</span></div>
                            <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                                <!-- <li><a href="#"><span class="dw-icon"><img src="../../../../assets/images/settings.svg"></span> Settings</a></li> -->
                                <li><a (click)="doLogout()"><span class="dw-icon"><img
                                                src="../../../../assets/images/logout.svg"></span> Sign Out</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section>
        <div class="container pd-container">
            <div class="row">
                <div class="col-12 policy-container">
                    <app-sub-header [fromPage]="strPayment"></app-sub-header>
                    <div class="pay-now-screen card-top-num d-flex">
                        <div class="top-card-img">
                            <div class="row">
                                <div class="col-md-8 pr-5 pl-0" *ngIf="IsCardExist">
                                    <div class="payment-det-innet edit-card-tab">
                                        <div class="payment-sum-panel d-flex">
                                            <div class="pl-0 col-md-12">
                                                <div class="panel-box">
                                                    <div class="box-strip">PAYMENT SUMMARY</div>
                                                    <div class="box-cont">
                                                        <div>
                                                            <div class="recrutind-card-dt col-lg-6 col-md-6 col-xs-12">
                                                                <div *ngIf="isRecurring==true">
                                                                    <p>Date of Next <br>Payment</p>
                                                                </div>
                                                                <div *ngIf="isRecurring==false">
                                                                    <p>Date of Next Payment</p>
                                                                </div>
                                                                <div class="fs-25">
                                                                    <strong>{{policyDetailsModel.paymentDueDate |
                                                                        date:'EEEE'}}</strong>
                                                                    <span
                                                                        class="payment-dt">{{policyDetailsModel.paymentDueDate
                                                                        | date:'MM/dd/yyyy'}}</span>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="amount-charge wd-auto col-lg-6 col-md-6 col-xs-12">
                                                                <div *ngIf="isRecurring==true">
                                                                    <strong>Next Auto Pay Amount</strong>
                                                                </div>
                                                                <div *ngIf="isRecurring==false">
                                                                    <strong>Next Amount Due</strong>
                                                                </div>
                                                                <div class="amt-count">${{dueAmount | number : '1.2-2'}}
                                                                </div>
                                                                <div class="fs-14"><b>*Amount due includes applicable
                                                                        fees</b></div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12 col-lg-12 mb10 mt-10 payment-summary-cnt"
                                                            *ngIf="partialService.policyStatus=='CANCELLED - NON PAYMENT OF PREMIUM'">
                                                            By reinstating this policy, you agree and understand that
                                                            there will be no Insurance coverage from the date of
                                                            cancellation up until the date and time the payment is
                                                            received by the company
                                                        </div>
                                                        <div id="dvMainPaymentBox"
                                                            class="col-sm-12 col-md-12 col-lg-12 mb10">
                                                            <!-- class="class="payment-card-des"-->
                                                            <div class="pay_plan green_box pay_box_auto">
                                                                <div class="pull-left ptb-20 pt-10 pay_amount">Payment
                                                                    Amount</div>
                                                                <div class="pull-left ptb-20 pt-10">
                                                                    <div class="col-sm-12 pl-0 fs-13">Premium
                                                                        Amount:<span><b>
                                                                                ${{ premiumAmount | number :
                                                                                '1.2-2'}}</b></span>
                                                                    </div>
                                                                    <div class="col-sm-12 pl-0 fs-13" id="convFees">
                                                                        Convenience Fee:
                                                                        $6.00</div>
                                                                    <div class="col-sm-12 pl-0 fs-13" id="convFees">
                                                                        Payable Amount:
                                                                        ${{totalAmount | number :'1.2-2'}}</div>
                                                                </div>
                                                                <div class="input-group radio pay_radio">
                                                                    <span
                                                                        class="input-group-addon border-remove payment-icon"><i
                                                                            class="fa fa-usd ml-20"></i></span>
                                                                    <input type="text" min="0"
                                                                        class="custom-text form-control downpayment-text border-remove"
                                                                        #txtAmount
                                                                        value="{{ installmentAmount | number : '1.2-2' }}"
                                                                        (change)="ValidateAmount($event)">
                                                                </div>

                                                            </div>
                                                            <div class="payment-credit-card-note">
                                                                *The total amount to be charged to you for Credit Card
                                                                transactions will be the policy premium payment amount
                                                                plus any applicable fees and a processing fee assessed
                                                                by RateForce, LLC for the $6.00 convenience of choosing
                                                                the Credit Card transaction alternative payment option
                                                                ('convenience fee'). By submitting your policy payment
                                                                using a Credit Card, you acknowledge, understand and
                                                                agree to this convenience fee.
                                                            </div>
                                                            <div class="pay-btn gradient-btn" *ngIf="IsValidAmount">
                                                                <button type="button" class="pull-left"
                                                                    (click)="payNow(totalAmount)">Pay
                                                                    Now</button>
                                                            </div>
                                                            <div class="col-sm-7 pull-right"
                                                                *ngIf="this.ccCount != true ">

                                                                <label class="switch" style="margin-right:10px;">
                                                                    <input type="checkbox"
                                                                        [(ngModel)]="DefaultCard.isDefault"
                                                                        (change)="changeDefaultCard()">
                                                                    <span class="slider round"></span>
                                                                </label>
                                                                <span style="font-size:16px; color:#7dc4da;">
                                                                    <strong>Set this card as default</strong>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <app-alert></app-alert>
                                </div>
                                <div class="col-md-4 pr-0 right-add-card">
                                    <div class="gradient-btn float-right mobile_add_button">
                                        <button class="btn-sm" type="button" (click)="addNewCard()">+ Add Card</button>
                                    </div>
                                    <div class="payment-card-des">
                                        <!-- Display Card List in loop -->
                                        <div *ngFor="let card of cardList;">
                                            <div *ngIf="card.isDefault" (click)="cardSelection(card)">
                                                <div class="card-des-inner card-selected">
                                                    <div class="visa-logo" *ngIf="card.cardType == 'VISA'"><img
                                                            class="card-type" class="img-responsive"
                                                            src="../../../../assets/images/visa-icon.svg"></div>
                                                    <div class="visa-logo" *ngIf="card.cardType == 'MASTERCARD'"><img
                                                            class="card-type"
                                                            src="../../../../assets/images/mastercard-logo.png"></div>
                                                    <div class="visa-logo" *ngIf="card.cardType == 'DISCOVER'"><img
                                                            class="card-type"
                                                            src="../../../../assets/images/discover-network-logo.png">
                                                    </div>
                                                    <div class="visa-logo" *ngIf="card.cardType == 'AMEX'"><img
                                                            class="card-type"
                                                            src="../../../../assets/images/americanexpress-logo.jpg">
                                                    </div>
                                                    <div class="card-number fs-20"><span>XXXX</span> <span>XXXX</span>
                                                        <span>XXXX</span> <span>{{card.ccNumber}}</span>
                                                    </div>
                                                    <div class="card-small-numb">XXXX</div>
                                                    <div class="card-identity">
                                                        <div class="c-name">{{card.ccName}}</div>
                                                        <div class="exp-dt">
                                                            <span class="valid-thru">Valid<br>Thru</span>
                                                            <span
                                                                class="dt">{{card.ccExpMonth}}/{{card.ccExpYear}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="selected-sign"><img
                                                            src="../../../../assets/images/checkmark-for-verification.svg">
                                                    </div>
                                                </div>
                                                <div class="remove_button pay-btn gradient-btn">
                                                    <button type="button" (click)="removeCard()"
                                                        style="padding:10px 20px; z-index: 100;font-size:12px; background:indianred">Remove
                                                        Card</button>
                                                </div>
                                            </div>

                                            <div *ngIf="card.isDefault==false" attr.id="test"
                                                (click)="cardSelection(card)">
                                                <div class="card-des-inner card-1">
                                                    <div class="visa-logo" *ngIf="card.cardType == 'VISA'"><img
                                                            class="card-type" class="img-responsive"
                                                            src="../../../../assets/images/visa-icon.svg"></div>
                                                    <div class="visa-logo" *ngIf="card.cardType == 'MASTERCARD'"><img
                                                            class="card-type"
                                                            src="../../../../assets/images/mastercard-logo.png"></div>
                                                    <div class="visa-logo" *ngIf="card.cardType == 'DISCOVER'"><img
                                                            class="card-type"
                                                            src="../../../../assets/images/discover-network-logo.png">
                                                    </div>
                                                    <div class="visa-logo" *ngIf="card.cardType == 'AMEX'"><img
                                                            class="card-type"
                                                            src="../../../../assets/images/americanexpress-logo.jpg">
                                                    </div>
                                                    <div class="card-number fs-20"><span>XXXX</span> <span>XXXX</span>
                                                        <span>XXXX</span> <span>{{card.ccNumber}}</span>
                                                    </div>
                                                    <div class="card-small-numb">XXXX</div>
                                                    <div class="card-identity">
                                                        <div class="c-name">{{card.ccName}}</div>
                                                        <div class="exp-dt">
                                                            <span class="valid-thru">Valid<br>Thru</span>
                                                            <span
                                                                class="dt">{{card.ccExpMonth}}/{{card.ccExpYear}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-xs-12 pull-right">
                                        <img class="img-responsive"
                                            src="../../../../assets/images/supported-credit-card.png">
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div class="bott-card-detail">
                            <div class="row">
                                <div class="col-lg-7 col-md-7 col-xs-12">
                                    <div class="amount-charge">


                                        <!--<div class="amt-count">${{installmentAmount | number : '1.2-2'}}</div>-->
                                        <!-- <div class="amt-count custom-text-no">
                                        <span class="text-green-payment">$</span>
                                         <input type="number" min="0" class="custom-text" value="{{ installmentAmount }}" (change)="ValidateAmount($event)">
                                    </div>
                                   -->
                                        <!-- <div id="dvMainPaymentBox" class="col-sm-12 col-md-12 col-lg-12 mb10">
                                                           
                                            <div class="pay_plan green_box pay_box_auto">
                                                <div class="pull-left ptb-20 pt-10">Payment Amount</div>
                                                <div class="input-group radio pay_radio">
                                                    <span class="input-group-addon border-remove payment-icon"><i
                                                            class="fa fa-usd"></i></span>
                                                    <input type="text" min="0"
                                                        class="custom-text form-control downpayment-text border-remove"
                                                        #txtAmount value="{{ installmentAmount }}"
                                                        (change)="ValidateAmount($event)">
                                                </div>
                                                <div class="top_bottom_text" id="convFees"> *Includes Convenience Fee:
                                                    $5.00</div>
                                            </div>
                                            <div class="payment-credit-card-note">
                                                By Clicking Pay Now button, I authorize Rateforce LLC to charge my
                                                credit card on {{ displayDate }}
                                            </div>
                                            <div class="pay-btn gradient-btn" *ngIf="IsValidAmount"><button
                                                    type="button" class="pull-left" (click)="payNow(txtAmount)">Pay
                                                    Now</button></div>

                                        
                                        
                                        <app-alert></app-alert>
                                    </div> -->

                                        <br>

                                        <div class="select-payment-meth" style="margin-left:20%">
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12">
                            <p>Your security is important to us. We do not store your credit card information. Online
                                payments are passed via a secure socket layer to a payment processor where your
                                information is tokenized (whereby a random number is generated to represent your
                                payment). The payment processor is PCI compliant which ensures that your information is
                                being handled in accordance with industry security standards</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <app-footer></app-footer>
</body>