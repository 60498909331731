<body>
    <header>
        <div class="container" data-loader="dvPolicyDetail">
            <div class="header-inner d-flex row">
                <div class="header-logo">
                    <a href="#"><img src="../../../../assets/images/excepsure-logo.png"></a>
                </div>
                <nav class="navbar navbar-expand-md tabing_navigatiin navbar-dark">
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
					    <span class="navbar-toggler-icon"></span>
					 </button>
                    <div class="collapse navbar-collapse" id="collapsibleNavbar">
                        <ul class="navbar-nav">
                            <li class="nav-item active"><a class="nav-link pointer" (click)="policyDetail()">Policy Details</a></li>
                            <li class="nav-item"><a class="nav-link pointer" (click)="payment()">Payments</a></li>
                            <li class="nav-item"><a class="nav-link pointer" (click)="policyDocuments()">Policy Documents</a></li>
                        </ul>
                    </div>
                </nav>

                <div class="right_header d-flex">
                    <div class="my-account">
                        <div class="dropdown">
                            <div class="dropdown-toggle" id="menu1" data-toggle="dropdown"><span class="user-icon"><img src="../../../../assets/images/MyAccount.svg"></span> <span class="mobile_rob">{{firstName}} {{lastName}}</span></div>
                            <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                                <!-- <li><a href="#"><span class="dw-icon"><img src="../../../../assets/images/settings.svg"></span> Settings</a></li> -->
                                <li><a (click)="doLogout()"><span class="dw-icon"><img src="../../../../assets/images/logout.svg"></span> Sign Out</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section>
        <div class="container pd-container">
            <div class="row">
                <div class="col-12 policy-container">
                    <app-sub-header [fromPage]='strPayment'></app-sub-header>
                    
                    <div class="payment-sum-panel d-flex">
                        <div class="pl-0 col-md-8">
                            <div class="panel-box">
                                <div class="box-strip">PAYMENT SUMMARY</div>
                                <div class="box-cont">
                                    <div class="recrutind-card-dt">
                                        <div *ngIf="isRecurring==true">
                                            <p>Date of Next <br>Payment</p>
                                        </div>
                                        <div *ngIf="isRecurring==false">
                                            <p>Date of Next Payment</p>
                                        </div>
                                        <div class="fs-25"><strong>{{policyDetails.paymentDueDate | date:'EEEE'}}</strong> 
                                            <span class="payment-dt">{{policyDetails.paymentDueDate | date:'MM/dd/yyyy'}}</span>
                                            <span class="payment-dt payment-cancel-box" *ngIf="isCancelPolicy">Policy Cancelled On {{policyDetails.cancelDate}}</span>
                                        <div class="pay-btn gradient-btn edit"><button type="button" (click)="payNowRedirection()">Edit Card Details</button></div>
                                        </div>
                                    </div>
                                    <div class="amount-charge">
                                        <div *ngIf="isRecurring==true">
                                            <strong>Next Auto Pay Amount</strong>
                                        </div>
                                        <div *ngIf="isRecurring==false">
                                            <strong>Next Amount Due</strong>
                                        </div>

                                        <!-- <strong>Next Amount to be Charged</strong> -->
                                        <div class="amt-count">${{policyDetails.amount | number : '1.2-2'}}</div>
                                        <div><b>*Amount due includes applicable fees</b></div>
                                        <div class="pay-btn gradient-btn" *ngIf="policyDetails!=null && policyDetails.amount > 0"><button type="button" (click)="payNowRedirection()">Make Payment</button></div>
                                        <!-- <div class="select-payment-meth"><a href="#">Choose payment method</a></div> 
                                        <div class="select-payment-meth"><a>Choose payment method</a></div>-->
                                    </div>
                                    <div class="payment-calc fs-20">
                                        <ul>
                                            <li>Total 6 Month Premium:<span>${{policyDetails.sixMonthPremium  | number : '1.2-2'}}</span></li>
                                            <li>Remaining:<span>${{policyDetails.outstandingBalance | number : '1.2-2'}}</span></li>
                                            <li>Amount of Last Payment:<span>${{policyDetails.lastReceivedAmount | number : '1.2-2'}}</span></li>
                                            <li>Last Payment Received:<span>{{policyDetails.lastReceivedAmtDate  | date:'MM/dd/yyyy'}}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 pl-2 pr-0">
                            <div class="panel-box">
                                <div class="box-strip">NAMED INSURED</div> <!-- <span class="strip-right-text"><a href="#">Edit</a></span>-->
                                <div class="box-cont">
                                    <div class="insured-box">
                                        <h2 class="main-heading">{{policyDetails.firstName}} {{policyDetails.lastName}}</h2>
                                        <div *ngFor="let driver of autoDrivers">
                                            <div>
                                                <p class="fs-20" style="margin:0;"><strong>{{driver.driverId}}. {{driver.name}}</strong></p>
                                            </div>
                                            <!-- <div *ngIf="driver.isExcluded==true">
                                                <p class="fs-20"><strong>{{driver.name}}:</strong> {{driver.relationName}} (excxluded)</p>
                                            </div>
                                            <div *ngIf="driver.isExcluded==false">
                                                <p class="fs-20"><strong>{{driver.name}} {{driver.lastName}}:</strong> {{driver.relationName}}</p>
                                            </div> -->
                                        </div>
                                        <span class="address fs-20"><strong>{{policyDetails.garageAddress1}}<br>
											{{policyDetails.garageCity}} {{policyDetails.garageState}} {{policyDetails.garageZip}}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-box veh-box" *ngIf="isVehicleExist">
                                <div class="box-strip">Vehicles</div>
                                <div class="divVehicle">
                                    <div *ngFor="let vehicle of autoVehicles">
                                        <div class="gray-strip">{{vehicle.year}} {{vehicle.model}} {{vehicle.make}} </div>
                                        <div class="box-cont py-3">
                                            <span class="prodc-info"><strong>VIN:</strong>{{vehicle.vin}}
                                            <br/>
                                            <strong>Lienholder:</strong> {{vehicle.lhName}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-box veh-box" *ngIf="isAgencyInfoExist">
                                <div class="box-strip">Agency Details</div>
                                <div class="divVehicle">
                                    <div class="box-cont py-3">
                                        <span class="prodc-info"><strong>Agency : </strong>{{agencyInfo.agencyName}}
                                        <br/>
                                        <strong>Contact No :</strong> {{agencyInfo.contactNo}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="id-cards-panel row">
                        <div class="col-md-12">
                            <div class="panel-box">
                                <div class="box-strip">ID CARDS
                                    <div class="strip-icons">
                                        <span class="email"><img src="../../../../assets/images/email.svg"></span>
                                        <span class="fax"><img src="../../../../assets/images/fax.svg"></span>
                                        <span class="printer"><img src="../../../../assets/images/print-symbol.svg"></span>
                                    </div>
                                </div>
                                <div class="box-cont">
                                    <div class="id-card-img row">
                                        <div class="col-md-6">
                                            <div class="card-img-inner">
                                                <img src="../../../../assets/images/id-card-1.jpg">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="card-img-inner">
                                                <img src="../../../../assets/images/id-card-1.jpg">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <app-contact-details></app-contact-details>
        </div>
    </section>
    <app-footer></app-footer>
</body>