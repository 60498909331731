import { Component, OnInit } from '@angular/core';
import {CustomerService} from '../../../core/services/customer.service'
import {AlertService} from '../../../core/services/alert.service'
import {AbstractControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import {ICustomerDetailModel} from '../../../core/interfaces/app.interface';
import {Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import {md5} from '../../../core/utilities/md5';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css']
})
export class NewPasswordComponent implements OnInit {
  customerDetails: ICustomerDetailModel = <ICustomerDetailModel>{};
  IsCustomer:FormGroup;
  isCustomerDetail: boolean = false;
  policyNumber:string;
  isForgotPassword:boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private customerService:CustomerService,
    public datepipe: DatePipe,
    private formBuilder:FormBuilder,
    private alertservice:AlertService

  ) { }

  ngOnInit(){
    this.IsCustomer=this.formBuilder.group({
      txtpassword: [null, [Validators.required]],
      txtconfirmpassword: [null, [Validators.required,this.passwordConfirming]],
    })
    this.policyNumber=localStorage.getItem('policynumber');
    if(localStorage.getItem('forgotPolicyNumber')!=null && localStorage.getItem('forgotPolicyNumber')!=undefined)
    {
      this.policyNumber = localStorage.getItem('forgotPolicyNumber').toString();
      this.isForgotPassword = true;
    }
    if (localStorage.getItem('alertmsg') != null && localStorage.getItem('alertmsg') != '') 
    {
      this.alertservice.success(localStorage.getItem('alertmsg'));
    }
  }

  
  get txtpassword() { return this.IsCustomer.get('txtpassword'); }
  get txtconfirmpassword() { return this.IsCustomer.get('txtconfirmpassword'); }

  postCustomerDetail(){
    if(this.IsCustomer.valid)
    {
      if(this.isForgotPassword) // Forgot Password Identification
      {
        this.customerDetails.mode ="U";
      }
      else
      {
        this.customerDetails.mode ="I";
      }
      this.customerDetails.policyNumber=this.policyNumber;
      let password = md5(this.customerDetails.password);
      this.customerDetails.customerPassword=password;
      this.customerService.postCustomerDetail(this.customerDetails).subscribe(data=>{
        if (data.status == 400) 
        {
          this.alertservice.warning(data.message);
          localStorage.setItem('alertmsg', data.message);
          
        }
        else 
        {
          debugger;
          this.alertservice.success('Data saved successfully');
          localStorage.setItem('alertmsg', 'Data saved successfully');
          localStorage.setItem('AuthToken', data["data"].token);
          this.router.navigate(["/policy-details/" + this.policyNumber]);
        }
      })
    }else{  
      this.alertservice.warning('Please fill all required details');
      return false;
    }
  }

  passwordConfirming(c: AbstractControl): any {
    if (!c.parent || !c) return;
    const pwd = c.parent.get('txtpassword');
    const cpwd = c.parent.get('txtconfirmpassword')
    if (!pwd || !cpwd) return;
    if (pwd.value !== cpwd.value) {
      return { invalid: true };
    }
  }

}
