<body>
    <header>
        <div class="container">
            <div class="header-inner d-flex row">
                <div class="header-logo">
                    <a href="#"><img src="../../../../assets/images/excepsure-logo.png"></a>
                </div>
            </div>
        </div>
    </header>
    <section>
        <div class="container" data-loader="dvRegistration">
            <div class="row">
                <div class="col-12 login-scr-cvr signup-screeen">
                    <div class="col-md-5 col-12 float-left login-form text-white text-center">
                        <h1 class="welcome-text">Welcome to Our Customer Portal</h1>
                        <span class="fs-25">Enter SignUp Information</span>
                        <app-alert></app-alert>
                        <form method="post" [formGroup]='signUp' novalidate (ngSubmit)="signUpSubmit()">
                            <div class="form-field">
                                <h2 class="policy-ttl"><span class="blue-color">Note:</span>Please enter your full policy number as listed on your welcome email.</h2>

                                <input [(ngModel)]="signUpDetails.policyNumber" formControlName="txtpolicynumber" class="form-control" type="text" name="" placeholder="Enter full Policy number" autofocus>
                                
                                <div *ngIf="txtpolicynumber.invalid && (txtpolicynumber.dirty || isSignUp)" class="alert alert-danger">
                                    <span *ngIf="txtpolicynumber.errors.required">
                                      PolicyNumber is required.
                                    </span>
                                </div>
                            </div>
                            <div class="form-field">
                                <input mask="00/00/0000" placeholder="MM/dd/yyyy" [dropSpecialCharacters]="false" [(ngModel)]="signUpDetails.birthDate" formControlName="dob" class="form-control" type="text" name="" placeholder="Date of Birth">
                                <div *ngIf="dob.invalid && (dob.dirty || isSignUp)" class="alert alert-danger">
                                    <span *ngIf="dob.errors.required">
                                      BirthDate is required.
                                    </span>
                                    <span *ngIf="dob.errors.pattern">
                                        Date of Birth must be a valid date in the format MM/DD/YYYY
                                      </span>
                                </div>
                            </div>
                            <div class="form-field">
                                <input [(ngModel)]="signUpDetails.zipCode" formControlName="txtzipcode" class="form-control" type="text" name="" placeholder="Zip Code">
                                <div *ngIf="txtzipcode.invalid && (txtzipcode.dirty || isSignUp)" class="alert alert-danger">
                                    <span *ngIf="txtzipcode.errors.required">
                                      ZipCode is required.
                                    </span>
                                </div>
                            </div>
                            <div class="form-field-submit">
                                <input type="submit" name="" value="Sign Up">
                            </div>
                        </form>
                        <div class="signup-link fs-25">Already have an account? <a [routerLink]='["/login"]'>Sign In</a></div>
                    </div>
                    <div class="col-md-6 col-12 float-right login-screen-disc text-white px-0">
                        <h2 class="big-title">Online Access Benefits</h2>
                        <ul class="fs-25">
                            <li>View your policy</li>
                            <li>Go paperless</li>
                            <li>Get ID cards</li>
                            <li>Pay your bill</li>
                            <li>View account balance and more</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <app-footer></app-footer>
</body>