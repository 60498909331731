import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/observable';
import { of } from 'rxjs/observable/of';
import {ErrorObservable} from 'rxjs/observable/ErrorObservable';
import {catchError} from 'rxjs/operators';
import {AppConstants} from '../utilities/app.constant';
import { Injectable } from '@angular/core';
import { IPolicyDetails } from '../interfaces/app.interface';
import {ICCDetailModel}  from '../interfaces/app.interface';
import { environment } from 'src/environments/environment';
import { DebugHelper } from 'protractor/built/debugger';

@Injectable({
    providedIn: 'root'
  })

  export class PaymentService{
    constructor(
        private http: HttpClient,
    ) { }

    PayPolicyInstallment(PolicyDetail: IPolicyDetails):Observable<any>{
      debugger;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'AuthToken': localStorage.getItem('AuthToken')
              })
        };
        return this.http.post(environment.urls.apiBaseURL + 'v1/payInstallment',PolicyDetail,httpOptions)
        .pipe(catchError(this.handleError));
    }

    AddCCDetails(CCDetails: ICCDetailModel):Observable<any>{
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'AuthToken': localStorage.getItem('AuthToken')
              })
        };
        console.log("ccDetails : " + CCDetails.policyNumber);
        return this.http.post(environment.urls.apiBaseURL + 'v1/saveCC',CCDetails, httpOptions)
        .pipe(catchError(this.handleError));
    }

    GetAllCards(policyNumber:string):Observable<any>{
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'AuthToken': localStorage.getItem('AuthToken')
            })
          };
          return this.http.get(environment.urls.apiBaseURL + 'v1/getAllCards/' + policyNumber,httpOptions)
            .pipe(catchError(this.handleError));
    }

    UpdateCard(CCDetails: ICCDetailModel):Observable<any>{
      const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'AuthToken': localStorage.getItem('AuthToken')
              })
        };
        return this.http.post(environment.urls.apiBaseURL + 'v1/updateCCDetails',CCDetails, httpOptions)
        .pipe(catchError(this.handleError));
    }

    changeRecurringOption(CCDetails:ICCDetailModel):Observable<any>{
      const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'AuthToken': localStorage.getItem('AuthToken')
              })
        };
        return this.http.post(environment.urls.apiBaseURL + 'v1/recurringSetup',CCDetails, httpOptions)
        .pipe(catchError(this.handleError));
    }

     GetPaymentHistory(policyNumber:string,dueDate:string):Observable<any>{
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'AuthToken': localStorage.getItem('AuthToken')
            })
          };
          return this.http.get(environment.urls.apiBaseURL + 'v1/getPaymentHistory?policyNumber=' + policyNumber+'&nextDueDate='+dueDate,httpOptions)
            .pipe(catchError(this.handleError));
    }

    GetCardDetailsById(policyNumber:string,cardId:number):Observable<any>{
      const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'AuthToken': localStorage.getItem('AuthToken')
          })
        };
        return this.http.post(environment.urls.apiBaseURL + 'v1/getCardDetailById?policyNumber=' + policyNumber+'&cardId='+cardId,httpOptions)
          .pipe(catchError(this.handleError));
  }

    
 IsPaymentCaptured(policyNumber:string):Observable<any>{
  const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'AuthToken': localStorage.getItem('AuthToken')
      })
    };
    return this.http.get(environment.urls.apiBaseURL + 'v1/getPaymentCapturedToday/'+ policyNumber,httpOptions)
      .pipe(catchError(this.handleError));
}

    GetDocumentStatus(policyNumber:string):Observable<any>{
      const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'AuthToken': localStorage.getItem('AuthToken')
          })
        };
        return this.http.get(environment.urls.apiBaseURL + 'v1/get-document-status/' + policyNumber,httpOptions)
          .pipe(catchError(this.handleError));
  }

    GetSignURL(policyNumber:string,cancelDate:string):Observable<any>
    {
      const httpOptions = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'AuthToken': localStorage.getItem('AuthToken')
            })
      };
      return this.http.get(environment.urls.apiBaseURL + 'v1/get-sign-url?policyNumber='+ policyNumber+'&cancelDate='+cancelDate,httpOptions)
      .pipe(catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status == 401) {
            return new ErrorObservable(error.error.message);
        }
        console.log('Status:' + error.status + ',' + error.error.message);
        return new ErrorObservable(error.error.message);
    };

    GetCCCardCount(policyNumber:string):Observable<any>{
      debugger;
      const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'AuthToken': localStorage.getItem('AuthToken')
          })
        };
        return this.http.get(environment.urls.apiBaseURL + 'default-card-count?policyNumber=' + policyNumber,httpOptions)
          .pipe(catchError(this.handleError));
  } 
  }