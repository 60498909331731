<body>
    <header>
        <div class="container">
            <div class="header-inner d-flex row">
                <div class="header-logo">
                    <a href="#"><img src="../../../../assets/images/excepsure-logo.png"></a>
                </div>
                <nav class="navbar navbar-expand-md tabing_navigatiin navbar-dark">
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
					    <span class="navbar-toggler-icon"></span>
					 </button>
                    <div class="collapse navbar-collapse" id="collapsibleNavbar">
                        <ul class="navbar-nav">
                            <li class="nav-item active"><a class="nav-link" (click)="policyDetails()">Policy Details</a></li>
                            <li class="nav-item"><a class="nav-link" (click)="payment()">Payments</a></li>
                            <li class="nav-item"><a class="nav-link" (click)="policyDocuments()">Policy Documents</a></li>
                        </ul>
                    </div>
                </nav>

                <div class="right_header d-flex">
                    <div class="my-account">
                        <div class="dropdown">
                            <div class="dropdown-toggle" id="menu1" data-toggle="dropdown"><span class="user-icon"><img src="../../../../assets/images/MyAccount.svg"></span> <span class="mobile_rob">{{firstName}} {{lastName}}</span></div>
                            <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                                <!-- <li><a href="#"><span class="dw-icon"><img src="../../../../assets/images/settings.svg"></span> Settings</a></li> -->
                                <li><a (click)="doLogout()"><span class="dw-icon"><img src="../../../../assets/images/logout.svg"></span> Sign Out</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section>
        <div class="container pd-container">
            <div class="row">
                <div class="col-12 policy-container">
                    <div class="policy-header d-flex">
                        <div class="pol-header-l">
                            <h1 class="main-heading">Policy Information</h1>
                            <span class="login-time">Last Login Sunday January 26th 2020. 04:47PM EST</span>
                        </div>
                        <div class="pol-header-r fs-20">
                            <span class="pol-detl"><strong>Policy No:</strong> {{policyNumber}}</span>
                            <span class="pol-detl"><strong>Policy Period:</strong> {{effectiveDate}} - {{expirationDate}}</span>
                        </div>
                    </div>
                    <div class="payment-sum-panel d-flex">
                        <div class="col-md-12 px-0">
                            <div class="panel-box policy-docs">
                                <div class="box-strip">NAMED INSURED <span class="strip-right-text"><a href="#">Save Changes</a></span></div>
                                <div class="box-cont">
                                    <div class="insured-box">
                                        <h2 class="main-heading">Robert F. Waters</h2>
                                        <p class="fs-20"><strong>Rachel L Pilger:</strong> Spouse (excxluded)</p>
                                    </div>
                                    <div class="edit-policy-info">
                                        <div class="p-input-field">
                                            <div class="full-input">
                                                <input type="text" name="">
                                                <label>Address</label>
                                            </div>
                                        </div>
                                        <div class="p-input-field d-flex">
                                            <div class="half-input">
                                                <input type="text" name="">
                                                <label>City</label>
                                            </div>
                                            <div class="half-input state-field">
                                                <input type="text" name="">
                                                <label>State</label>
                                            </div>
                                            <div class="half-input">
                                                <input type="text" name="">
                                                <label>Zip Code</label>
                                            </div>
                                        </div>
                                        <div class="p-input-field d-flex">
                                            <div class="half-input">
                                                <input type="text" name="">
                                                <label>Number</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <app-footer></app-footer>
</body>