import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PolicyService } from '../../../core/services/policy.service';
import { Subject } from 'rxjs';
import { Observable, throwError } from 'rxjs';
import { AlertService } from '../../../core/services/alert.service';
import { isNullOrUndefined } from 'util';
import { DatePipe } from '@angular/common';

// Declare loader function
declare function loadingServiceShow(zindex, id, flag);
declare function loadingServiceHide(id);

@Component({
  selector: 'app-redirection',
  templateUrl: './redirection.component.html',
  styleUrls: ['./redirection.component.css']
})
export class RedirectionComponent implements OnInit {
  policyNumber: string;
  token: string;

  constructor(
    private route: ActivatedRoute,
    private policyService: PolicyService,
    private alertService: AlertService,
    private router: Router,
    public datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.token = this.route.snapshot.params['token'];
    this.router.navigate(["/login"]);
    // this.getRecurringPolicyDetailsByToken(this.token);
  }

  getRecurringPolicyDetailsByToken(token: string) 
  {
    
    if (!isNullOrUndefined(token)) 
    {
      this.policyService.RecurringPolicyDetailsByToken(token).subscribe(data => 
      {
        
        if (data.data != null) 
        {
          localStorage.setItem('firstname', data.data.firstName);
          localStorage.setItem('lastname', data.data.lastName);
          localStorage.setItem('policynumber', data.data.policyNumber);
          localStorage.setItem('effectiveDate', data.data.effectiveDate);
          localStorage.setItem('expirationDate', data.data.expirationDate);
          localStorage.setItem('installmentAmount', data.data.amount);
          sessionStorage.setItem('dueAmount',data.data.amount);
          localStorage.setItem('isRecurring', data.data.isRecurring);
          localStorage.setItem('lastLoginDate', this.datepipe.transform(data.data.lastLoginDate,'MM/dd/yyyy hh:mm:ss a'));
          this.router.navigate(["/payment-card-details"]);
        } else {
          this.alertService.warning("Invalid Policy Number");
        }
        loadingServiceHide('dvRedirection');
      });
    }
    else
    {
      this.router.navigate(["/login"]);
    }
  }
}
