<div class="modal-header">
      <h4 class="modal-title pull-left">Payment Sign-Form</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" data-loader="ifrmDisplayReport">
      <iframe [src]="signURL"  id="ifrm" #ifrm width="100%" frameborder="0"
        webkitallowfullscreen mozallowfullscreen allowfullscreen scrolling="no" frameborder="0"
        style="position: relative; width: 100%;height:1200px;"></iframe>
    </div>
    <!-- <div class="modal-footer">
      <button type="button" class="btn btn-outline-primary" (click)="close()">Close</button>
    </div> -->
  

    
  