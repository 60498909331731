<body>
    <header>
        <div class="container">
            <div class="header-inner d-flex row">
                <div class="header-logo">
                    <a href="#"><img src="../../../../assets/images/excepsure-logo.png"></a>
                </div>
            </div>
        </div>
    </header>
    <section>
        <div class="container" data-loader="dvLogin">
            <div class="row">
                <div class="col-12 login-scr-cvr login-form-main">
                    <div class="col-md-5 col-12 float-left login-form text-white text-center">
                        <h1 class="welcome-text">Welcome to Our Customer Portal</h1>
                        <span class="fs-25">Enter Login Information</span>
                        <app-alert></app-alert>
                        <form method="post" [formGroup]='login' novalidate (ngSubmit)="loginSubmit()">
                            <div class="form-field login-form-sec">
                                <label class="fs-25">Email/Policy Number</label>
                                <h2 class="login-form-ttl"><span class="blue-color">Note:</span>Please enter your full policy number as listed on your welcome email.</h2>
                                <input [(ngModel)]="loginDetails.policyNumber" placeholder="Enter Full Policy number" formControlName="loginpolicynumber" class="form-control" type="text" name="">
                                <div *ngIf="loginpolicynumber.invalid && (loginpolicynumber.dirty || islogin)" class="alert alert-danger">
                                    <span *ngIf="loginpolicynumber.errors.required">
                                        Email/PolicyNumber is required.
                                    </span>
                                </div>
                            </div>
                            <div class="form-field">
                                <label class="fs-25">Password</label>
                                <input [(ngModel)]="loginDetails.loginPassword" placeholder="Enter Password" formControlName="loginPassword" class="form-control" type="password" name="">
                                <div *ngIf="loginPassword.invalid && (loginPassword.dirty || islogin)" class="alert alert-danger">
                                    <span *ngIf="loginPassword.errors.required">
                                        Email/PolicyNumber is required.
                                    </span>
                                </div>
                            </div>
                            <div class="form-field-submit">
                                <input type="submit" name="" value="Login">
                            </div>
                        </form>
                        <div class="signup-link fs-25">Don't have an account? <a [routerLink]='["/sign-up"]'>Sign Up</a></div>
                        <div class="signup-link fs-25"><a [routerLink]='["/forgot-password"]'>Forgot password?</a></div>
                    </div>
                    <div class="col-md-6 col-12 float-right login-screen-disc text-white px-0">
                        <h2 class="big-title">Online Access Benefits</h2>
                        <ul class="fs-25">
                            <li>View your policy</li>
                            <li>Go paperless</li>
                            <li>Get ID cards</li>
                            <li>Pay your bill</li>
                            <li>View account balance and more</li>
                        </ul>
                        <div>
                            <h4>Do you need to renew your policy?</h4>
                            <div class="form-field-submit">
                                <input type="button" style="border-radius: 10px !important;" value="Renew Your Policy" data-toggle="modal" data-target="#myModal" (click)="show()">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!-- Model Popup Window start-->
    <div *ngIf="showModal" >
        <div id="myModal" class="modal fade">
          <div class="modal-dialog">
              <div class="modal-content">
                  <div class="modal-body">
                    <app-alert></app-alert>
                    <form method="post" [formGroup]='signUp' novalidate >
                        <div class="form-field" style="margin-bottom: 2%;">
                            <input [(ngModel)]="signUpDetails.policyNumber" formControlName="txtpolicynumber" class="form-control" type="text" name="" placeholder="Policy Number" autofocus>
                            <div *ngIf="txtpolicynumber.invalid && (txtpolicynumber.dirty || isSignUp)" class="alert alert-danger">
                                <span *ngIf="txtpolicynumber.errors.required">
                                  PolicyNumber is required.
                                </span>
                            </div>
                        </div>
                        <div class="form-field" style="margin-bottom: 2%;">
                            <input mask="00/00/0000" [dropSpecialCharacters]="false" [(ngModel)]="signUpDetails.birthDate" formControlName="dob" class="form-control" type="text" name="" placeholder="Date of Birth">
                            <div *ngIf="dob.invalid && (dob.dirty || isSignUp)" class="alert alert-danger">
                                <span *ngIf="dob.errors.required">
                                  BirthDate is required.
                                </span>
                            </div>
                        </div>
                        <div class="form-field-submit" style="width: 30%; display: inline-flex; margin-left: 25%;">
                            <input type="submit" name="" class="btnRenewal" value="Renew" style="display: inline-block; cursor: pointer;" (click)="checkRenewal()">
                            <input type="button" name="" class="btnRenewal" value="Cancel" style="display: inline-block; cursor: pointer;" (click)="hide()" >
                        </div>
                    </form>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <!-- Model Popup Window end-->

    <app-footer></app-footer>
</body>