import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ICustomerDetailModel } from 'src/app/core/interfaces/app.interface';
import { AlertService } from 'src/app/core/services/alert.service';
import { CustomerService } from 'src/app/core/services/customer.service';
import { isNullOrUndefined } from 'util';

// Declare loader function
declare function loadingServiceShow(zindex, id, flag);
declare function loadingServiceHide(id);
@Component({
  selector: 'app-password-verification',
  templateUrl: './password-verification.component.html',
  styleUrls: ['./password-verification.component.css']
})
export class PasswordVerificationComponent implements OnInit {

  customerDetails: ICustomerDetailModel = <ICustomerDetailModel>{};
  frmForgotPasswordVerification:FormGroup;
  isCustomerDetail: boolean = false;
  isVerification:boolean = false;
  isRedirected:boolean = false;
  policyNumber:string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private customerService:CustomerService,
    public datepipe: DatePipe,
    private formBuilder:FormBuilder,
    private alertservice:AlertService
  ) { }

  ngOnInit() 
  {
    this.policyNumber = this.route.snapshot.params['policynumber'].toString();
    this.frmForgotPasswordVerification =this.formBuilder.group({
      policyNumber:[''],
      txtverification:[null,[Validators.required]],
    })
  }

  get txtverification() {return this.frmForgotPasswordVerification.get('txtverification');}

  verifyForgotPassword(txtverification:any)
  {
    if(this.frmForgotPasswordVerification.valid && !isNullOrUndefined(this.policyNumber))
    {
      loadingServiceShow(1, 'dvForgotPasswordVerification', true);
      localStorage.setItem("forgotPolicyNumber",this.policyNumber);
      let verificationCode = txtverification.value;
      this.customerService.verifyForgotPassword(this.policyNumber,verificationCode).subscribe(data=>{
      if (data.status == 400 || data.status == 401) {
        this.alertservice.warning(data.message);
        localStorage.setItem('alertmsg', data.message);
      }
      else {
        this.router.navigate(["/password-confirmation/"]);
      }
      loadingServiceHide('dvForgotPasswordVerification');
      },error => {
        this.alertservice.error('Invalid Verification Code Try Again!');
        loadingServiceHide('dvForgotPassword');
      });
    }
    else
    {
      this.alertservice.warning('Please fill required details');
      return false;
    }
  }

}
