<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalTitle">Make Payment</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">X</button>
    </div>
    <div class="modal-body" data-loader="dvPaymentDetail">
        <div class="pymnt-scrn-sec">
            <div class="amout-dt">
                <div class="dt-due">
                    <label>Amount Due</label>
                    <h2>$<span>{{ installmentAmount | number : '1.2-2' }}</span></h2>
                </div>
                <div class="dt-due">
                    <label>Payment Due Date</label>
                    <h3>{{policyDetailsModel.paymentDueDate | date:'MM/dd/yyyy'}}</h3>
                </div>
            </div>
            <div class="pymnt-radio-check">
                <label class="rdio-btn"  *ngFor="let card of cardList;">
                    {{card.ccName}}
                     <span class="crd-num">**** **** {{card.ccNumber}} 
                         <i class="fa fa-cc-mastercard" aria-hidden="true" *ngIf="card.cardType == 'MASTERCARD'"></i>
                         <i class="fa fa-cc-visa" aria-hidden="true" *ngIf="card.cardType == 'VISA'"></i>
                         <i class="fa fa-cc-discover" aria-hidden="true" *ngIf="card.cardType == 'DISCOVER'"></i>
                         <i class="fa fa-cc-amex" aria-hidden="true" *ngIf="card.cardType == 'AMEX'"></i></span>
                <input type="radio" [value]="card.id"  [checked]="card.isDefault" name="radio" [(ngModel)]="selectedCcId">
                  <span class="checkmark"></span>
                </label>
            </div>
        </div>
        <app-alert></app-alert>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary pull-left" (click)="payNow()">Pay
        Now</button>
    </div>
</div>