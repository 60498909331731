import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { PartialsModule } from './content/partials/partials.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { LoginComponent } from './content/pages/login/login.component';
import { SignUpComponent } from './content/pages/sign-up/sign-up.component';
import { PaymentSuccessComponent } from './content/pages/payment-success/payment-success.component';
import { PolicyDocumentsComponent } from './content/pages/policy-documents/policy-documents.component';
import { PolicyDetailsComponent } from './content/pages/policy-details/policy-details.component';
import { PolicyDetailsEditComponent } from './content/pages/policy-details-edit/policy-details-edit.component';
import { PaymentHistoryComponent } from './content/pages/payment-history/payment-history.component';
import { PaymentCardDetailsComponent } from './content/pages/payment-card-details/payment-card-details.component';
import { PaymentAddCardComponent } from './content/pages/payment-add-card/payment-add-card.component';
import { NgxMaskModule } from 'ngx-mask';
import { LoginService } from './core/services/login.service';
import { AlertService } from './core/services/alert.service';
import { NewPasswordComponent } from './content/pages/new-password/new-password.component';
import { RedirectionComponent } from './content/pages/redirection/redirection.component';
import { AuthGuardService } from './core/services/auth-guard.service';
import { PaymentSignDocumentComponent } from './content/pages/payment-sign-document/payment-sign-document.component';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { ForgotPasswordComponent } from './content/pages/forgot-password/forgot-password.component';
import { PasswordVerificationComponent } from './content/pages/password-verification/password-verification.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PaymentInstallmentComponent } from './content/pages/payment-installment/payment-installment.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignUpComponent,
    PaymentSuccessComponent,
    PolicyDocumentsComponent,
    PolicyDetailsComponent,
    PolicyDetailsEditComponent,
    PaymentHistoryComponent,
    PaymentCardDetailsComponent,
    PaymentAddCardComponent,
    NewPasswordComponent,
    RedirectionComponent,
    PaymentSignDocumentComponent,
    ForgotPasswordComponent,
    PasswordVerificationComponent,
    PaymentInstallmentComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    CoreModule,
    NgxMaskModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    // RouterModule.forRoot(appRoutes),
    PartialsModule
  ],
  providers: [
    Title,
    LoginService,
    AlertService,
    AuthGuardService,
    BsModalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
