import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './content/pages/login/login.component';
import { SignUpComponent } from './content/pages/sign-up/sign-up.component';
import { PaymentSuccessComponent } from './content/pages/payment-success/payment-success.component';
import { PolicyDocumentsComponent } from './content/pages/policy-documents/policy-documents.component';
import { PolicyDetailsComponent } from './content/pages/policy-details/policy-details.component';
import { PolicyDetailsEditComponent } from './content/pages/policy-details-edit/policy-details-edit.component';
import { PaymentHistoryComponent } from './content/pages/payment-history/payment-history.component';
import { PaymentCardDetailsComponent } from './content/pages/payment-card-details/payment-card-details.component';
import { PaymentAddCardComponent } from './content/pages/payment-add-card/payment-add-card.component';
import { NewPasswordComponent } from './content/pages/new-password/new-password.component';
import { RedirectionComponent } from './content/pages/redirection/redirection.component';
import { AuthenticationGuard } from './core/services/authentication.guard';
import { ForgotPasswordComponent } from './content/pages/forgot-password/forgot-password.component';
import { PasswordVerificationComponent } from './content/pages/password-verification/password-verification.component';
import { LayoutModule } from './content/layout/layout.module';
const routes: Routes = [

	{
		path: 'layout',
		// loadChildren: 'app/content/layout/layout.module#LayoutModule',
		loadChildren: () => import('./content/layout/layout.module').then(m => LayoutModule)
	},
	{
		path: '',
		component: LoginComponent
	},
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: 'sign-up',
		component: SignUpComponent
	},
	{
		path: 'sign-up/:quote',
		component: SignUpComponent
	},
	{
		path: 'payment-success',
		component: PaymentSuccessComponent,
		canActivate: [AuthenticationGuard]
	},
	{
		path: 'policy-documents/:policynumber',
		component: PolicyDocumentsComponent,
		canActivate: [AuthenticationGuard]
	},
	{
		path: 'policy-details/:policynumber',
		component: PolicyDetailsComponent,
		canActivate: [AuthenticationGuard]
	},
	{
		path: 'policy-details-edit',
		component: PolicyDetailsEditComponent,
		canActivate: [AuthenticationGuard]
	},
	{
		path: 'payment-history/:policynumber',
		component: PaymentHistoryComponent,
		canActivate: [AuthenticationGuard]
	},
	// {
	// 	path: 'payment-card-details/:s',
	// 	component: PaymentCardDetailsComponent
	// },
	{
		path: 'payment-card-details',
		component: PaymentCardDetailsComponent

	},
	{
		path: 'payment-card-details',
		component: PaymentCardDetailsComponent,
		canActivate: [AuthenticationGuard]
	},
	{
		path: 'payment-add-card',
		component: PaymentAddCardComponent,
		canActivate: [AuthenticationGuard]
	},
	{
		path: 'password-confirmation',
		component: NewPasswordComponent
	},
	{
		path: 'forgot-password',
		component: ForgotPasswordComponent
	},
	{
		path: 'verification-forgot-password/:policynumber',
		component: PasswordVerificationComponent
	},
	{
		path: 'redirection/:token',
		component: RedirectionComponent,
	},
	{
		path: '**',
		redirectTo: ''
	},

];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			preloadingStrategy: PreloadAllModules, useHash: true, onSameUrlNavigation: 'reload'
		},
		)
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
