<div class="policy-header d-flex">
    <div class="pol-header-l">
        <h1 class="main-heading">{{ headerTitle }}</h1>
        <span class="login-time" *ngIf="lastLoginExist"><strong>Last Login : </strong> {{lastLoginDate}} </span>
    </div>
    <div class="pol-header-r fs-20">
        <span class="pol-detl"><strong>Policy No:</strong> {{policyNumber}}</span>

        <div *ngIf="isStatusExist && partialService.policyStatus!='' && partialService.policyStatus!= undefined  && partialService.policyStatus != null then statusBlock;"></div>

        <ng-template #statusBlock>
            <div class="pol-detl"><strong>Status:</strong> &nbsp;
                <span [ngClass]="partialService.policyStatus.toLowerCase().includes('cancelled') ? 'fc-red' : partialService.policyStatus.toLowerCase().includes('active') ? 'fc-green' : '' " ><b>{{partialService.policyStatus}}</b></span>
            </div>
        </ng-template>
         <div class="pol-detl" *ngIf="partialService.policyStatus!='' && partialService.policyStatus != undefined && partialService.policyStatus != null && (partialService.policyStatus.toLowerCase().includes('cancelled') || partialService.policyStatus.toLowerCase().includes('notice')) && partialService.cancelDate!=''"><strong>Cancel Date: </strong><span class="fc-red"><b>{{partialService.cancelDate}}</b></span></div>
    </div>
</div>