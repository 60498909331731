<header>
    <div class="container">
        <div class="header-inner d-flex row">
            <div class="header-logo">
                <a href="#"><img src="../../../../assets/images/excepsure-logo.png"></a>
            </div>
        </div>
    </div>
</header>
<section>
    <div class="container" data-loader="dvForgotPassword">
        <div class="row">
            <div class="col-12 login-scr-cvr">
                <div class="col-md-5 col-12 float-left login-form text-white text-center">
                    <h1 class="welcome-text">Welcome to Our Customer Portal</h1>
                    <span class="fs-25">Forgot Password</span>
                    <app-alert></app-alert>
                    <form method="post" [formGroup]='frmForgotPassword' novalidate>
                        <div class="form-field" *ngIf="!isRedirected">
                            <label class="fs-25">Policy Number</label>
                            <input formControlName="txtpolicynumber" class="form-control" type="text" name="" #txtpolicynumber>
                            <div *ngIf="txtpolicynumber.invalid && (txtpolicynumber.dirty || isCustomerDetail)" class="alert alert-danger">
                                <span *ngIf="txtpolicynumber.errors.required">
                                    Email/PolicyNumber is required.
                                </span>
                            </div>
                        </div>
                        <div class="form-field-submit">
                            <input type="submit" name="" value="Submit" (click)="sendForgotPasswordLink(txtpolicynumber)">
                        </div>
                    </form>
                </div>
                <div class="col-md-6 col-12 float-right login-screen-disc text-white px-0">
                    <h2 class="big-title">Online Access Benefits</h2>
                    <ul class="fs-25">
                        <li>View your policy</li>
                        <li>Go paperless</li>
                        <li>Get ID cards</li>
                        <li>Pay your bill</li>
                        <li>View account balance and more</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>
