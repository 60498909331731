import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {LayoutComponent} from './layout.component';

@NgModule({
    declarations: [

		LayoutComponent
    ],
    imports: [
		CommonModule,
		RouterModule.forChild([
			{
				path: 'layout',
				component: LayoutComponent
			}
		])
	],
})
export class LayoutModule { }